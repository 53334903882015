import store from "../store";
import { i18n } from "../utils/i18n";

let kickOut = setTimeout(() => {
  store.commit("SET_ERROR", {
    message: { data: i18n.t("lbl_session_expired_message") },
    status: "900",
  });
}, parseFloat(process.env.VUE_APP_TIMEOUT) * 60000);

document.addEventListener("click", (evnt) => {
  clearTimeout(kickOut);
  kickOut = setTimeout(() => {
    store.commit("SET_ERROR", {
      message: { data: i18n.t("lbl_session_expired_message") },
      status: "900",
    });
  }, parseFloat(process.env.VUE_APP_TIMEOUT) * 60000);
});
