<template>
<div class="guest-information-main">
  <RateDetailsSummary v-if="mobileView"></RateDetailsSummary>
    <div class="guest-information-container">
      <!-- <div class="title m-t-10 m-b-10">Guest Information</div> -->

      <GuestForm ref="guest-form" ></GuestForm>
    </div>
    <RateDetailsSummary v-if="!mobileView"></RateDetailsSummary>
  <div class="navigation">
    <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
    <el-button class="next" type="primary" :loading="loading"
                @click="nextStep()">
      {{ $t('lbl_next') }}</el-button>
  </div>
</div>
</template>

<script>
import GuestForm from '@/views/activities/activity-steps/GuestInformation/GuestForm'
import apiCall from '@/services/activities/api-calls-activities.service'
import RateDetailsSummary from '../../components/RateDetailsSummary.vue'

import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    GuestForm,
    RateDetailsSummary
  },
  name: "GuestInformation",
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      startActivityMap: state => state.activities.startActivityMap,
      priceDetails: state => state.activities.priceDetails,
      activityDetails: state => state.activities.activityDetails,
      property: state => state.activities.propertyDetails,
      mobileView: state => state.mobileView
    })
  },
  methods: {
    ...mapMutations({
      SET_PAYMENT_TYPES: 'activities/SET_PAYMENT_TYPES',
      SET_GUEST_DATA: 'activities/SET_GUEST_DATA'
    }),
    async sendGuestInformation(payload){
      try{
          this.loading = true;

          // let params = {
          //   submit_url: this.startActivityMap.map.submit_url,
          //   session_id: this.startActivityMap.map.session_id
          // }
          // console.log(params);

          const response = await apiCall.post(`${this.property.id}/web/new-activity-booking/${this.startActivityMap.id}/basic-info/${this.startActivityMap.session_id}`,payload, {} ,'eventManagementApi')
          let payment_types = response.data?.client_visible_data?.payment_types
          console.log(response)
          this.SET_PAYMENT_TYPES(payment_types)

          this.loading = false;
        }
        catch(error) {
          console.log(error)
        }

    },
    async guestData(){
      let payload = await this.$refs['guest-form'].validateForm()
        const reqPayload = {
          adults: this.priceDetails.adults,
          children: this.priceDetails.children,
          contact_info: payload
        }
        console.log(reqPayload);
        if(!reqPayload.contact_info) return;
        this.sendGuestInformation(reqPayload);
        this.SET_GUEST_DATA(reqPayload.contact_info);


        console.log(payload);
    },
    async previousStep() {
      // let payload = await this.$refs['guest-form'].validateForm()
      // this.guestData();

      this.$router.push({name: 'activity-details'}).catch(() => {})

    },
    async nextStep() {
      let payload = await this.$refs['guest-form'].validateForm()
      this.guestData();
        if(payload) {
          this.$router.push({name: 'activity-payments'}).catch(() => {})
        }

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';

.guest-information-main{
  margin: 30px 80px 30px;
  display: flex;
  gap: 50px;
  padding-bottom: 50px;
  .guest-information-container{
    width: 75%;
  }
}

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
  }

  @include media("<=tablet") {
    .guest-information-main{
      margin: 10px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      padding-bottom: 50px;
      .guest-information-container{
        width: 100%;
      }
    }
  }



/* .navigation button {
  border: 1px solid $lightgrey-2;
  border-radius: 5px;
} */
</style>
