<template>
    <div>
        <template v-for="(room, roomIndex) in getFilteredRooms">
                <div class="room-wrapper" v-if="room.rate_codes" :key="roomIndex">
                    <div class="room">
    
                        <div class="carousel-container">
                            <image-carousel :showImgCarousel="showImgCarousel" :images="roomImages(showImgCarousel ? clickedRoom : room)" :additional_details="roomAdditionalDetails(showImgCarousel ? clickedRoom : room)" :room="showImgCarousel ? clickedRoom : room" @updateShowImgCarousel="updateShowImgCarousel" :roomIndex="roomIndex"/>
                        </div>
    
                    <div class="room-desc"
                        :style="{ 'max-height': (room.rate_codes && room.rate_codes.length > 2 && !mobileView) ? (86 * room.rate_codes.length) + 'px' : 'unset' }">
                        <div  :class="{ 'flexed-column': mobileView }" class="title">
                        <span @click.stop="toggleModal(room)">{{roomDetails(room)  && translateValues(roomDetails(room).code, roomDetails(room).name, 'ROOM_TYPES', roomDetails(room).id, 'name' )
                            }}</span>
                            <span v-if="preferredRoom(room)" class="preferred-tag">{{ $t("lbl_selected_room") }}</span>
                        </div>
                        <room-amenities :room="room"></room-amenities>
                        <span v-if="showMore(room)">{{ description(room).substr(0, 177) }}...</span>
                        <div style="max-width: 300px; max-height: 300px; overflow-y: auto; overflow-wrap: break-word;" v-else>{{
                            description(room) }}</div>
    
                        <div class="m-t-5 show-more">
                            <span v-if="showMore(room)" @click="room.showMore = !room.showMore" class="pointer ">{{ $t("lbl_show_more") }}</span>
                            <span v-else-if="showLess(room)" class="pointer" @click="room.showMore = !room.showMore">{{ $t("lbl_show_less") }}</span>
                        </div>
                    </div>
    
                    <div class="lowest-rate">
    
                        <price-information :rate="findLowestRoomRate(room.rate_codes)"></price-information>
    
                        <div>
                            <el-button :plain="room.showRateCodes" :type="!room?.showRateCodes ? 'primary' : 'primary'" @click.stop="showRates(roomIndex)">{{
                                !room?.showRateCodes ? $t("lbl_view_prices") : $t("lbl_hide_prices")}}
                                <i
                                    :class="{ 'el-icon-arrow-down': !room?.showRateCodes, 'el-icon-arrow-up': room?.showRateCodes }"></i>
                            </el-button>
                        </div>
                    </div>
    
    
                </div>
                <div v-if="room?.showRateCodes">
                    <div class="available-rates">
                        <div class="rates-wrapper" ref="rates">
                            <div class="rates" v-for="(rate, rateIndex) in room.rate_codes" :key="rateIndex">
                                <div class="rate">
                                    <div class="rates-desc">
                                        <div class="sub-title">
                                           <span @click="openModal(room,rate)">{{  rateCodeDetails(rate).display_code ? translateValues(objectCode(rate) , rateCodeDetails(rate).display_code, objectType(rate), objectId(rate), 'display_code') :
                                                translateValues(objectCode(rate), rateCodeDetails(rate).name, objectType(rate), objectId(rate), 'name') }}</span>
                                                <i v-if="!rateCodeDetails(rate).additional_details && !rateCodeDetails(rate).description"  class="el-icon-info font-large m-l-5" @click="openModal(room,rate)"></i>
    
                                            <span v-if="applicablePackage && applicablePackage.id === rate.package_id"
                                                class="m-l-10 preferred-tag">{{ $t("lbl_preferred_package") }}</span>
                                            <!-- <span v-if="group_rate_plan && group_rate_plan === rate.id" class="m-l-10 preferred-tag">{{$t("lbl_preferred_group_rate_plan")}}</span> -->
                                        </div>
                                        <div v-if="!mobileView" style="padding-top: 6px; font-weight: 400" class="description m-b-5">
                                            <div style="height: 80px;" class="flexed align-top m-b-5">
                                                <div v-if="rateCodeDetails(rate).description">
                                                    <span style="overflow-wrap: break-word;" class="brief-desc" v-html="renderMarkdown(rateCodeDetails(rate).description?.length > 100 ? `${translateValues(objectCode(rate), rateCodeDetails(rate).description, objectType(rate), objectId(rate), 'description')?.slice(0, 99)}...` : translateValues(objectCode(rate), rateCodeDetails(rate).description, objectType(rate), objectId(rate), 'description'))"></span>
                                                    <i v-if="rateCodeDetails(rate).description"  class="el-icon-info font-large" @click="openModal(room,rate)"></i>
                                                </div>
                                                <div v-else>
                                                    <span style="overflow-wrap: break-word;" class="brief-desc" v-html="renderMarkdown(rateCodeDetails(rate).additional_details?.length > 100 ? `${translateValues(objectCode(rate),rateCodeDetails(rate).additional_details, objectType(rate), objectId(rate), 'additional_details')?.slice(0, 99)}...` : translateValues(objectCode(rate),rateCodeDetails(rate).additional_details, objectType(rate), objectId(rate), 'additional_details' ))"></span>
                                                    <i v-if="rateCodeDetails(rate).additional_details"  class="el-icon-info font-large" @click="openModal(room,rate)"></i>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="m-t-10" v-if="packageChargeTypes.length > 0">
                                            <div class="flexed justify-between" v-for="(item, index) in packageChargeTypes"
                                                :key="index">
                                                <div>{{ item.name }}</div>
                                                <div>{{ packageCharges(item.charges) | currency }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="desc">
                                            <price-information :priceInfo="true" :rate="rate"></price-information>
                                        <div class="book-btn">
                                            <el-button
                                                v-if="showRemoveBtn(room, rate, roomIndex, rateIndex) && !bookingInProgress"
                                                type="danger" plain :style="mobileBtn" style="width: 100%"
                                                @click="$emit('removeRoom', { room, rate, roomIndex, rateIndex })">{{ $t('lbl_remove') }}
                                            </el-button>
                                            <el-button v-else type="primary" :style="mobileBtn" style="width: 100%"
                                                @click="bookRoom(room, rate, roomIndex, rateIndex, 'book_room')"
                                                :disabled="bookingInProgress"
                                                :loading="selectedData && selectedData.roomIndex === roomIndex && selectedData.rateIndex === rateIndex && bookingInProgress && !booking_room_unit">
    
                                                <span v-if="rate.package_id">{{ $t("lbl_book_package") }}</span>
                                                <span v-else>{{ $t("lbl_book_room") }}</span>
                                            </el-button>
                                            <template v-if="enable_room_unit_booking">
                                                <div class="or-divider">
                                                    <span>{{ $t("lbl_or") }}</span>
                                                </div>
                                                <el-button
                                                    v-if="showRemoveBtn(room, rate, roomIndex, rateIndex) && !bookingInProgress"
                                                    type="danger" plain :style="mobileBtn" style="width: 100%"
                                                    @click="$emit('removeRoom', { room, rate, roomIndex, rateIndex })">{{ $t("lbl_remove") }}
                                                </el-button>
                                                <el-button v-else type="primary" :style="mobileBtn" style="width: 100%"
                                                    @click="bookRoom(room, rate, roomIndex, rateIndex, 'book_room_unit')"
                                                    :disabled="bookingInProgress"
                                                    :loading="selectedData && selectedData.roomIndex === roomIndex && selectedData.rateIndex === rateIndex && bookingInProgress && booking_room_unit"><span>{{ $t("lbl_book_room_unit") }}</span>
                                                </el-button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="divider" v-if="room.rate_codes.length > 0"></div> -->
                            </div>
                        </div>
                    </div>
                    <modal
                        v-if="dialogVisible"
                        size="45%"
                        @close="dialogVisible = false"
                        style="margin-bottom: 50px;"
                        >
                        <div slot="modal-title"> <h3 class="m-t-0 m-b-0">{{ rateData.rateTitle }}</h3> </div>
                        <div slot="content">
                            <price-details-popup :rateModalData="rateData"></price-details-popup>
                        </div>
                        <div slot="modal-footer">
                            <el-button type="danger" @click="dialogVisible = false">{{ $t("lbl_close") }}</el-button>
                        </div>
                    </modal>
                </div>
            </div>
        </template>
        <BookRoomUnit v-if="showRoomUnitModal" @close="closeRoomUnitModal" :selectedRoomPayload="selectedRoomPayload" />
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ImageCarousel from '../component/ImageCarousel.vue';
import BookRoomUnit from "./BookRoomUnit.vue";
import RoomAmenities from "@/views/booking-v2/component/RoomAmenities.vue";
import PriceInformation from "./PriceInformation.vue";
import Modal from "@/components/Modal.vue";
import CommonService from '@/services/common.services';
import { translateDynamicContent } from "@/services/utilities.service";

import dayJs from "dayjs";
import PriceDetailsPopup from "./PriceDetailsPopup.vue";
import { marked } from 'marked'

export default {
    name: "DisplayByRoomsV2",
    components: { BookRoomUnit, ImageCarousel, RoomAmenities, PriceInformation, Modal, PriceDetailsPopup },
    props: ['getFilteredRooms', 'roomIndex', 'bookingInProgress', 'selectedData'],
    data() {
        return {
            descMaxLength: 150,
            showRoomUnitModal: false,
            selectedRoomPayload: {},
            booking_room_unit: false,
            dialogVisible: false,
            rateData: null,
            showImgCarousel: false,
            roomsClickedForBooking:[],
            clickedRoom: null

        }
    },
    computed: {
        ...mapState({
            mobileView: state => state.mobileView,
            sessions: state => state.profile.sessions,
            search_data: state => state.search_data,
            discount_applied: state => state.discount_applied,
            applicablePackage: state => state.applicablePackage,
            rate_content: state => state.rate_content,
            selected_room_details: state => state.selected_room_details,
            allFilters: state => state.allFilters,
            room_type_amenity_groups: state => state.room_type_amenity_groups,
            bed_type_groups: state => state.bed_type_groups,
            packages: state => state.packages,
            rwa_data: (state) => state.rwa_data,

            roomTypes: state => state.room_types,
            rateCodes: state => state.rate_codes,
            packageChargeTypes: state => state.package_charge_types,
            enable_book_with_points: state => state.enable_book_with_points,
            enable_room_unit_booking: state => state.profile.restrictions.booking_profile.allow_room_unit_booking,
            clicked_rooms: state => state.clicked_rooms,
            property_currency: state => state.property.property_currency,
            property: state => state.property.details,
            multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
            property_language_code: state => state.property.details.language

        }),
        mobileBtn() {
            return this.mobileView ? { 'height': '50px' } : ''
        },
        group_rate_plan() {
            return this.rwa_data?.group_block?.group_rate_plan;
        }
    },
    methods: {

        ...mapMutations({
            SET_CLICKED_ROOMS: 'SET_CLICKED_ROOMS'
        }),

        removeRoom() {
        },
        // markedText(description) {
        //   if (description) {
        //     const lines = description.split('.');
        //     const bulletPoints = lines.map((line) => {
        //       const trimmedLine = line.trim();
        //       if (trimmedLine.includes('*')) {
        //         return `<li>${trimmedLine}</li>`;
        //       }
        //       return trimmedLine || '';
        //     });
        //     const markdownContent = bulletPoints.join('\n');
        //     return marked(markdownContent, { sanitize: true });
        //   }
        //   return '';
        // },
        renderMarkdown(markedText) {
            if (markedText) {
                return marked(markedText, { sanitize: true })
            }
            return '';
        },
        // async getRatePriceInfo(room, rate) {
        //     let session_id = this.sessions[this.roomIndex];
        //     let roomSearchData = this.search_data.rooms[this.roomIndex];
        //     let payload = {
        //         check_in_date: dayJs(roomSearchData.checkIn).format('YYYY-MM-DD'),
        //         check_out_date: dayJs(roomSearchData.checkOut).format('YYYY-MM-DD'),
        //         rate_code_id: rate?.id,
        //         room_type_id: room?.room_type_id,
        //         package_id: null,
        //         number_of_adults: roomSearchData.persons.adults,
        //         number_of_children: roomSearchData.persons.children,
        //         children_ages: roomSearchData?.persons?.children_ages?.map(age => String(age)),
        //         rate_details: [],
        //     }
        //     CommonService.setRate(payload, session_id)
        //         .then(async ({ data }) => {

        //             console.log('session id', session_id)

        //             const calcdata = await CommonService.getProfileFolioTaxes(session_id);
        //             console.log('data', calcdata)

        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         })
        // },
        bookRoom(room, rate, roomIndex, rateIndex, source) {
            if (source === "book_room_unit" && this.enable_room_unit_booking) {
                this.booking_room_unit = true // required for the loader
                this.selectedRoomPayload = { room, rate, activeTab: this.roomIndex, rateIndex }
                this.showRoomUnitModal = true
            }
            else if (source === "book_room") {
                this.booking_room_unit = false // required for the loader
                if (!this.roomsClickedForBooking.includes(room)) {
                    this.roomsClickedForBooking.push({room: this.roomsClickedForBooking, roomIndex});
                    this.SET_CLICKED_ROOMS(this.roomsClickedForBooking)
                }
                this.goNext({ room, rate, roomIndex, rateIndex }, null)
            }
        },
        closeRoomUnitModal(payload) {
            // if there was any room selected, its id will come as payload, otherwise we just close the modal
            if (payload) {
                this.showRoomUnitModal = false
                this.selectedRoomPayload.room.room_id = payload.room_id
                this.selectedRoomPayload.room.room_unit_number = payload.room_unit_number
                this.goNext(this.selectedRoomPayload)
            }

            this.showRoomUnitModal = false
            this.selectedRoomPayload = {}
        },
        goNext(args) {
            if (args.rate.price_summary.total_points && this.enable_book_with_points) {
                let points = args.rate.price_summary.total_points
                args.points = points
            }
            this.$emit('bookRoom', args)
        },
        showRemoveBtn(room, rate) {
            let currentRoom = this.selected_room_details.find(room => room.room_no === this.roomIndex)
            return currentRoom?.hasOwnProperty('package_id') ?
                currentRoom?.room_type_id === room.room_type_id && currentRoom?.rate_code_id === rate.id && currentRoom.package_id === rate?.package_id :
                currentRoom?.room_type_id === room.room_type_id &&
                currentRoom?.rate_code_id === rate.id &&
                !rate.hasOwnProperty('package_id')
        },
        preferredRoom(room) {
            if (this.$route.query.hasOwnProperty('rn_code')) {
                return this.$route.query.rn_code === this.roomDetails(room).code
            }
        },
        packageRateDetails(obj) {
            return this.packages[this.roomIndex].find(pack => pack.id === obj.package_id)
        },
        showMore(room) {
            return room.showMore && this.description(room) && this.description(room).length > this.descMaxLength
        },
        showLess(room) {
            return !room.showMore && this.description(room) && this.description(room).length > this.descMaxLength
        },
        description(room) {
            // console.log('room name', this.roomDetails(room)?.name, 'room code', this.roomDetails(room)?.code)
            return this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.description, 'ROOM_TYPES',  this.roomDetails(room)?.id, 'description') 
        },
        roomAdditionalDetails(room) {
            return this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.additional_details, 'ROOM_TYPES', this.roomDetails(room)?.id, 'additional_details' ) 
        },
        packageCharges(charges) {
            return charges.reduce((b, a) => b + a.net_value_excl_taxes, 0);
        },
        rateCodeDetails(item) {
            if (item.hasOwnProperty('package_id')) {
                item.package_description = `${(this.packages[this.roomIndex].find(pack => pack.id === item.package_id).description || '')}`
                // item.additional_details = `${(this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id).additional_details || '')}`
                let rateCode = this.rateCodes[this.roomIndex]?.find(rate => rate?.id === item?.id)
                let ratePack = this.packages[this.roomIndex]?.find(pack => pack?.id === item?.package_id)?.package_rate_codes?.find(item => item?.rate_code_id === rateCode?.id)
                let ratePackageCode = this.rateCodes[this.roomIndex]?.find(rate => rate?.id === item?.id && ratePack?.rate_code_id === rateCode?.id)

                item.additional_details = `${ratePackageCode.additional_details || ''}`
                item.description = `${(this.packages[this.roomIndex].find(pack => pack.id === item.package_id).description || '')}`
                item.package_code = this.packages?.[this.roomIndex]?.find(pack => pack.id == item.package_id)?.code
                return item
            }
            return this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id)
        },
        roomDetails(item) {
            if (item.hasOwnProperty('package_id')) {
                return item
            }
            return this.roomTypes[this.roomIndex]?.find(room => room.id === item.room_type_id)
        },
        roomImages(room) {
            let room_details = this.roomDetails(room)
            if (!room_details)
                return
            let arr = [room_details.cover_photo, ...room_details.images.map(i => i.url)]
            return arr
        },
        findLowestRoomRate(rate_codes) {
            let lowestRate = rate_codes?.find(rate => {
                return Math.min(rate.rate_amount)
            })
            return lowestRate
        },
        showRates(roomIndex) {
            if (!this.getFilteredRooms[roomIndex].hasOwnProperty('showRateCodes')) {
                this.$set(this.getFilteredRooms[roomIndex], 'showRateCodes', true);
            } else {
                this.getFilteredRooms[roomIndex].showRateCodes = !this.getFilteredRooms[roomIndex].showRateCodes;
            }
        },
         openModal(room,rate) {
            this.dialogVisible = true

            let cancellation_policy = this.rwa_data?.cancellation_policies?.find(item => item?.id === rate?.cancellation_policy_id)
            let rateCode = this.rateCodes[this.roomIndex]?.find(rateC => rateC?.id === rate?.id)

            this.rateData =  {
                rateTitle: this.translateValues(this.objectCode(rate), this.rateCodeDetails(rate).display_code, this.objectType(rate), this.objectId(rate), 'display_code' )  || this.translateValues(this.objectCode(rate), this.rateCodeDetails(rate).name, this.objectType(rate), this.objectId(rate), 'name'),
                package_description: this.translateValues(this.objectCode(rate), this.rateCodeDetails(rate).package_description, this.objectType(rate), this.objectId(rate), 'description') ,
                additional_details: this.translateValues(this.objectCode(rate), this.rateCodeDetails(rate).additional_details, this.objectType(rate), this.objectId(rate), 'additional_details'),
                additional_details_popup: rate?.package_id ? this.translateValues(rateCode?.code, this.rateCodeDetails(rate).additional_details, 'RATE_CODES', rate?.id, 'additional_details') : this.translateValues(this.objectCode(rate), this.rateCodeDetails(rate).additional_details, this.objectType(rate), this.objectId(rate), 'additional_details'), 
                cancellation_policy: this.translateValues(cancellation_policy?.code, cancellation_policy?.description, 'CANCELLATION_POLICY', cancellation_policy?.id, 'description'),
                price_summary: rate?.price_summary,
                rateDetails: rate
            }
        },
        toggleModal(room) {
            this.clickedRoom = {...room, room_type_name: this.translateValues(this.roomDetails(room)?.code, room.room_type_name, 'ROOM_TYPES', this.roomDetails(room)?.id, 'name')} 
            this.showImgCarousel = true;
        },
        updateShowImgCarousel(value) {
            this.showImgCarousel = value
        },
        objectCode(rate) {   
            if(this.rateCodeDetails(rate).package_code) {
                return this.rateCodeDetails(rate).package_code
            }
            return this.rateCodeDetails(rate)?.code
        },
        objectType(rate) {   
            if(this.rateCodeDetails(rate).package_code) {
                return 'PACKAGE_RATES'
            }
            return 'RATE_CODES'
        },
        objectId(rate) {
            if(this.rateCodeDetails(rate).package_code) {
                return this.rateCodeDetails(rate).package_id
            }
            return this.rateCodeDetails(rate).id
        },
        translateValues(object_code, value, object_type, object_id, field_name) {
           return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
        }

    },
    async beforeMount() {
      this.clicked_rooms?.forEach((room) => {
        this.showRates(room.roomIndex);
      });
    }

}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";


:deep(.el-icon-close) {
    margin-left: 100%;
}
:deep(.description p) {
 margin-top: 0px;
}

:deep(.list-desc ul) {
    margin-bottom: 20px;
}

// .desc div {
//     display: flex;
//     flex-direction: column;
//     align-self: end;
// }

:deep(.brief-desc p) {
    display: inline !important;
    overflow: auto;
    word-break: break-all;
    margin-top: 0px;
}

.lowest-rate {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}


.price-desc-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
}



:deep(.description ul) {
    margin-left: 20px !important;
    list-style: disc !important;
    width: 100%;
}

.preferred-tag {
    background-color: $hk-blue;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: normal;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.room-desc {
    font-size: 14px;
    width: 40%;

    // text-align: justify;
    // border: solid #dadada 1px;
    // border-radius: 5px;
    // padding: 10px;
    .show-more {
        color: $hk-primary-color;
    }

}


@include media('>=desktop') {

    .wrapper {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        .room-wrapper {
            border: solid #dadada 1px;
            border-radius: 5px;
            padding: 16px;
            margin-top: 10px;
            // display: flex;
        }

        //   Room box
        .room {
            width: 100%;
            display: flex;
            gap: 20px;

            .carousel-container {
                width: 29%;
                height: 100%;

                .img {
                    margin: 0px;
                }
            }

            .desc {
                // display: flex;
                padding: 0 2px;
                width: 100%;
                text-align: justify;
                overflow: auto;
                max-height: 250px;
            }
        }


        //   Rates Box
        .available-rates {
            // flex: 1;

            .rates-wrapper {

                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
                // flex-wrap: wrap;
                margin-top: 20px;

                .rates {
                    // display: grid;
                    // grid-template-columns: 1fr 1fr 1fr;
                    // flex: 1 1 1;
                    // margin: 0 0 0 0;

                    .rate {
                        display: flex;
                        flex-direction: column;
                        // justify-content: space-between;
                        // align-items: center;
                        padding: 20px;
                        // width: 20%;
                        border: 1px solid #dadada;
                        border-radius: 5px;
                        // margin: 10px 0 0 0;
                        height: 350px;


                        .rates-desc {
                            align-self: start;
                            width: 100%;
                            height: 120px;
                            // overflow: auto;

                            .description {
                                padding: 0 2px;
                                width: 100%;
                                text-align: justify;
                                // overflow: auto;
                                // max-height: 250px;
                                font-size: 14px;
                                font-weight: 400 !important;
                                font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, 'Lato', Arial, sans-serif;
                            }

                            .price {
                                font-size: 18px;
                            }

                        }

                        .discount-price {
                            font-size: 10px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .base-amount-before-tax {
                            font-size: 11px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .desc {
                            text-align: end;
                            // display: flex;
                            margin-top: auto;
                            gap: 20px;

                            .price {
                                font-size: 16px;
                            }

                            .book-btn {
                                margin-top: 20px;
                                min-width: 148px;

                                .or-divider {
                                    display: flex;
                                    justify-content: space-around;
                                    font-size: 12px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                }
                            }

                            .price-desc {
                                font-size: 12px;
                                font-weight: 400;
                                // text-align: end;

                            }

                        }
                    }

                    .divider {
                        margin: 10px;
                    }

                    &:not(:last-child) {
                        .divider {
                            border-bottom: 1px #dadada solid;
                        }
                    }
                }
            }
        }
    }
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
    display: flex;
    align-items: center;
}


@include media('<=desktop') {
    .room-desc {
        width: 100%;
    }

    .title {
        margin: 0px 0;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #4E4E4E;
    }

    .wrapper {
        flex-direction: column;
        font-size: 14px;
        padding: 0 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        margin-bottom: 20px;

        .room-wrapper {
            border: solid #dadada 1px;
            border-radius: 5px;
            padding: 16px;
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            margin: 15px 25px;
        }

        .room {
            .desc {
                text-align: justify;
            }
        }

        .available-rates {
            .title {
                margin-top: 10px;
            }

            .rates-wrapper {
                .rates {
                    .rate {
                        margin: 5px 0;
                        padding: 10px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        // background-color: #fcfcfc;

                        .rates-desc {
                            .price {
                                font-size: 16px;
                            }
                        }

                        .discount-price {
                            font-size: 10px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .base-amount-before-tax {
                            font-size: 11px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .desc {
                            margin: 10px 0;
                            gap: 20px;

                            .price {
                                font-size: 16px;
                            }

                            .book-btn {
                                margin-top: 20px;
                            }
                        }
                    }

                    .divider {
                        margin: 15px 0px;
                    }


                }
            }
        }
    }

    @include media('<desktop') {
        .lowest-rate {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        .desc {
            justify-content: space-between;
        }


    }

    .mobile-btn {
        height: 50px !important;
    }

    .desc {
        display: flex !important;
    }
}</style>

