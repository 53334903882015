import { render, staticRenderFns } from "./HkPhoneInput.vue?vue&type=template&id=3254b71b&scoped=true"
import script from "./HkPhoneInput.vue?vue&type=script&lang=js"
export * from "./HkPhoneInput.vue?vue&type=script&lang=js"
import style0 from "./HkPhoneInput.vue?vue&type=style&index=0&id=3254b71b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3254b71b",
  null
  
)

export default component.exports