<template>
  <div>
    <template v-for="(room, roomIndex) in getFilteredRooms" >
      <div class="room-wrapper" v-if="room.rate_codes" :key="roomIndex">
          <div class="room" >
            <div :class="{ 'flexed-column': mobileView }" class="title">{{ roomDetails(room) && translateValues(roomDetails(room).code, roomDetails(room).name, 'ROOM_TYPES', roomDetails(room).id, 'name') }}
              <span v-if="preferredRoom(room)"
                    class="preferred-tag">{{ $t("lbl_selected_room") }}</span>

            </div>
            <div class="carousel-container">
              <image-carousel :images="roomImages(room)"/>
            </div>

          <div class="room-desc"
               :style="{'max-height': (room.rate_codes && room.rate_codes.length > 2 && !mobileView) ? (86 * room.rate_codes.length) + 'px' : 'unset'}">
                <room-amenities :room="room"></room-amenities>
               <div v-if="showMore(room)">{{ description(room).substr(0, 30) }}...</div>
               <div style="max-width: 300px; max-height: 250px; overflow-y: auto; overflow-wrap: break-word;"  v-else>
                <div v-html="description(room)"></div>
              </div>

            <div class="m-t-5 show-more">
              <span v-if="showMore(room)" @click="room.showMore = !room.showMore" class="pointer ">{{ $t("lbl_show_more") }}</span>
              <span v-else-if="showLess(room)" class="pointer"
                    @click="room.showMore = !room.showMore ">{{ $t("lbl_show_less") }}</span>
            </div>
          </div>
        </div>
        <div class="available-rates">
          <!-- <div class="title">Available Rates</div> -->
          <div class="rates-wrapper" ref="rates">
            <div class="rates" v-for="(rate, rateIndex) in room.rate_codes" :key="rateIndex">
              <div class="rate">
                <div class="rates-desc">
                  <div class="sub-title">
                    {{ rateCodeDetails(rate).display_code ? translateValues(objectCode(rate), rateCodeDetails(rate).display_code, objectType(rate), objectId(rate), 'display_code') : translateValues(objectCode(rate), rateCodeDetails(rate).name, objectType(rate), objectId(rate), 'name')  }}
                    <i v-if="rateCodeDetails(rate).additional_details || rateCodeDetails(rate).package_description"  class="el-icon-info font-large info-icon" @click="openRateInfoModal(rate)"></i>
                    <span v-if="applicablePackage && applicablePackage.id === rate.package_id" class="m-l-10 preferred-tag">{{ $t("lbl_preferred_package") }}</span>
                    <!-- <span v-if="group_rate_plan && group_rate_plan === rate.id" class="m-l-10 preferred-tag">{{$t("lbl_preferred_group_rate_plan")}}</span> -->
                  </div>
                  <div v-if="!mobileView" style="padding-top: 6px; font-weight: 400" class="description"
                  >
                    <div>
                      <div v-if="rateCodeDetails(rate).description" style="margin-bottom: 20px;" v-html="renderMarkdown(formattedPackageDescription(translateValues(objectCode(rate),rateCodeDetails(rate).package_description, objectType(rate), objectId(rate),'description')))"></div>

                      <div v-if="rateCodeDetails(rate).additional_details"  v-html="renderMarkdown(formattedRatePlanDescription(translateValues(objectCode(rate), rateCodeDetails(rate).additional_details, objectType(rate), objectId(rate), 'additional_details')))"></div>

                    </div>
                  </div>
                  <div class="m-t-10" v-if="packageChargeTypes.length > 0">
                    <div class="flexed justify-between" v-for="(item, index) in packageChargeTypes" :key="index">
                      <div>{{ item.name }}</div>
                      <div>{{ packageCharges(item.charges) | currency }}</div>
                    </div>
                  </div>
                </div>
                <div class="desc">
                  <div v-if="ifRateCodeParamIsGoogle">
                    {{ rate.price_summary.total_amount_after_tax | currency }}
                  </div>
                  <div
                    v-else-if="rate.price_summary.total_points && enable_book_with_points"
                    class="flexed align-center justify-end" :class="{'justify-end': mobileView}"
                  >
                    <div class="price sub-title">{{ rate.price_summary.total_points | formatBigNumber }} {{ $t("lbl_points") }}</div>
                  </div>
                  <div v-else-if="discount_applied" class="flexed align-center" :class="{'justify-end': mobileView}">
                    <div class="discount-price  m-r-5" :class="{'flex_1': !mobileView}">
                      <div v-if="includeTaxFlag">{{rate.price_summary.avg_amount_after_tax_and_fee | currency }}</div>
                      <div v-else-if="rate.price_summary.total_inclusive_tax_amount === 0">{{rate.price_summary.conversion_total_amount_after_discount ? rate.price_summary.conversion_total_amount_after_discount : rate.price_summary.avg_amount_before_tax | currency }}</div>
                      <div v-else-if="rate.price_summary.total_exclusive_tax_amount === 0"> {{rate.price_summary.conversion_total_net_amount_after_discount ? rate.price_summary.conversion_total_net_amount_after_discount : rate.price_summary.avg_amount_after_tax | currency }}</div>
                      <!-- {{ rate.price_summary.conversion_total_amount_after_tax ? rate.price_summary.conversion_total_amount_after_tax : rate.price_summary.total_amount_after_tax | currency }} -->
                    </div>
                    <div>
                      <div class="price sub-title" v-if="includeTaxFlag">{{rate.price_summary.avg_net_amount_after_discount | currency }}</div>
                      <div class="price sub-title" v-else-if="rate.price_summary.total_inclusive_tax_amount === 0">{{rate.price_summary.conversion_total_amount_after_discount ? rate.price_summary.conversion_total_amount_after_discount : rate.price_summary.avg_amount_after_discount | currency }}</div>
                      <div class="price sub-title" v-else-if="rate.price_summary.total_exclusive_tax_amount === 0"> {{rate.price_summary.conversion_total_amount_after_discount ? rate.price_summary.conversion_total_amount_after_discount : rate.price_summary.avg_net_amount_after_discount | currency }}</div>
                    </div>
                    <!-- <div class="price sub-title" v-if="rate.price_summary.conversion_total_amount_after_discount"> {{ rate.price_summary.conversion_total_amount_after_discount | currency }}</div> -->
                    <!-- <div class="price sub-title">{{ rate.price_summary.conversion_avg_net_amount_after_discount ? rate.price_summary.conversion_avg_net_amount_after_discount : rate.price_summary.avg_net_amount_after_discount  | currency }}</div> -->
                  </div>


                  <!-- when strike_price or avg_amount_after_discount -->
                  <div v-else-if="rate.price_summary.strike_price || rate.price_summary.avg_amount_after_discount" class="flexed align-center" :class="{'justify-end': mobileView}">
                    <div class="discount-price  m-r-5" :class="{'flex_1': !mobileView}">
                      {{ includeTaxFlag ? rate.price_summary.avg_base_amount_after_tax : (rate.price_summary.avg_base_amount_before_tax + (rate.price_summary.avg_inclusive_base_tax_amount || 0)) | currency }}
                    </div>
                    <div class="price sub-title">{{ includeTaxFlag ? rate.price_summary?.avg_amount_after_tax_and_fee : (rate.price_summary.avg_amount_before_tax + (rate.price_summary.avg_inclusive_tax_amount || 0)) | currency }}</div>
                  </div>
                  <!--  -->

                  <div v-else-if="includeTaxFlag" class="price sub-title flexed-end w-100">
                    <!--                  {{ rate.price_summary.avg_amount_before_tax | currency }}-->
                                      {{ (rate.price_summary?.avg_amount_after_tax_and_fee ) | currency}}
                  
                                    </div>

                  <div v-else class="price sub-title flexed-end w-100">
  <!--                  {{ rate.price_summary.avg_amount_before_tax | currency }}-->
                    {{ (rate.price_summary.avg_amount_before_tax + (rate.price_summary.avg_inclusive_tax_amount || 0)) | currency}}

                  </div>
                  <template  v-if="includeTaxFlag || ifRateCodeParamIsGoogle">
                    <div class="price-desc">{{ $t("lbl_including_taxes_and_fees") }}</div>
                    <span class="price-desc" style="margin-right: 5px;">{{ $t('lbl_per_night_avg')  }} </span>
                </template>
                  <template v-else-if="!(rate.price_summary.total_points && enable_book_with_points)">
                    <div v-if="mobileView" class="flexed justify-end " style="font-size: 12px;">
                      <span class="price-desc" style="margin-right: 5px;">{{ $t('lbl_per_night_avg')  }} </span>
                      <span class="price-desc" v-if="rate.price_summary.avg_inclusive_tax_amount === 0 || rate.price_summary.avg_inclusive_tax_amount === null || !rate.price_summary.avg_inclusive_tax_amount">{{ $t("lbl_excluding_taxes_and_fees") }}</span>
                      <span v-else class="price-desc" >{{ $t("lbl_including_taxes_and_fees") }}</span>
                    </div>
                    <div v-else>
                      <div class="price-desc">{{ $t('lbl_per_night_avg')  }}</div>
                      <div class="price-desc" v-if="rate.price_summary.avg_inclusive_tax_amount === 0 || rate.price_summary.avg_inclusive_tax_amount === null || !rate.price_summary.avg_inclusive_tax_amount">{{ $t("lbl_excluding_taxes_and_fees") }}</div>
                      <div v-else class="price-desc">{{ $t("lbl_including_taxes_and_fees") }}</div>

                    </div>

                  </template>
                  <div class="book-btn">
                    <el-button
                      v-if="showRemoveBtn(room, rate, roomIndex, rateIndex) && !bookingInProgress"
                      type="danger"
                      plain
                      :style="mobileBtn"
                      style="width: 100%"
                      @click="$emit('removeRoom', {room, rate, roomIndex, rateIndex})"
                    > {{ $t('lbl_remove') }}
                    </el-button>
                    <el-button
                      v-else
                      type="primary"
                      :style="mobileBtn"
                      style="width: 100%"
                      @click="bookRoom(room, rate, roomIndex, rateIndex, 'book_room')"
                      :disabled="bookingInProgress"
                      :loading="selectedData && selectedData.roomIndex === roomIndex && selectedData.rateIndex === rateIndex && bookingInProgress && !booking_room_unit"
                    >

                      <span v-if="rate.package_id">{{ $t("lbl_book_package") }}</span>
                      <span v-else>{{ $t("lbl_book_room") }}</span>
                    </el-button>
                    <template v-if="enable_room_unit_booking">
                      <div class="or-divider">
                        <span>{{ $t("lbl_or") }}</span>
                      </div>
                      <el-button
                        v-if="showRemoveBtn(room, rate, roomIndex, rateIndex) && !bookingInProgress"
                        type="danger"
                        plain
                        :style="mobileBtn"
                        style="width: 100%"
                        @click="$emit('removeRoom', {room, rate, roomIndex, rateIndex})"
                      >{{ $t("lbl_remove") }}
                      </el-button>
                      <el-button
                        v-else
                        type="primary"
                        :style="mobileBtn"
                        style="width: 100%"
                        @click="bookRoom(room, rate, roomIndex, rateIndex, 'book_room_unit')"
                        :disabled="bookingInProgress"
                        :loading="selectedData && selectedData.roomIndex === roomIndex && selectedData.rateIndex === rateIndex && bookingInProgress && booking_room_unit"
                      ><span>{{ $t("lbl_book_room_unit") }}</span>
                      </el-button>
                    </template>
                  </div>
                </div>
              </div>
              <div class="divider" v-if="room.rate_codes.length > 0"></div>
            </div>
          </div>
        </div>
      </div>

    </template>
    <modal
        v-if="dialogVisible"
        size="45%"
        @close="dialogVisible = false"
        style="margin-bottom: 50px;"
        >
        <div slot="modal-title"></div>
        <div slot="content">
            <rate-description-popup :rateModalData="rateData"></rate-description-popup>
        </div>
        <div slot="modal-footer">
            <el-button type="danger" @click="dialogVisible = false">{{ $t("lbl_close") }}</el-button>
        </div>
    </modal>
    <BookRoomUnit v-if="showRoomUnitModal" @close="closeRoomUnitModal" :selectedRoomPayload="selectedRoomPayload"/>
  </div>

</template>

<script>
import {mapState} from "vuex";
import ImageCarousel from '../component/ImageCarousel.vue';
import BookRoomUnit from "./BookRoomUnit.vue";
import RoomAmenities from "@/views/booking-v2/component/RoomAmenities.vue";
import RateDescriptionPopup from '@/views/booking-v2/select/RateDescriptionPopup.vue'
import {marked} from 'marked'
import Modal from "@/components/Modal.vue";
import { translateDynamicContent } from "@/services/utilities.service";



export default {
  name: "DisplayByRooms",
  components: {BookRoomUnit, ImageCarousel,RoomAmenities, RateDescriptionPopup, Modal},
  props: ['getFilteredRooms', 'roomIndex', 'bookingInProgress', 'selectedData'],
  data() {
    return {
      descMaxLength: 150,
      showRoomUnitModal: false,
      selectedRoomPayload: {},
      booking_room_unit: false,
      dialogVisible: false,
      rateData: null
    }
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      sessions: state => state.profile.sessions,
      search_data: state => state.search_data,
      discount_applied: state => state.discount_applied,
      applicablePackage: state => state.applicablePackage,
      rate_content: state => state.rate_content,
      selected_room_details: state => state.selected_room_details,
      allFilters: state => state.allFilters,
      room_type_amenity_groups: state => state.room_type_amenity_groups,
      bed_type_groups: state => state.bed_type_groups,
      packages: state => state.packages,
      rwa_data: (state) => state.rwa_data,

      roomTypes: state => state.room_types,
      rateCodes: state => state.rate_codes,
      packageChargeTypes: state => state.package_charge_types,
      enable_book_with_points: state => state.enable_book_with_points,
      enable_room_unit_booking: state => state.profile.restrictions.booking_profile.allow_room_unit_booking,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language,
      propertyDetails: state => state.property.details,
    }),
    mobileBtn() {
      return this.mobileView ? {'height': '50px'} : ''
    },
    ifRateCodeParamIsGoogle(){
      return this.$route.query?.rate_code === 'GOOGLE'
    },
    group_rate_plan() {
      return this.rwa_data?.group_block?.group_rate_plan;
    },
    includeTaxFlag(){
      return this.propertyDetails.website_config?.display_tax_inclusive_rate_on_booking_engine
    }
  },
  methods: {
    removeRoom() {
    },
    // markedText(description) {
    //   if (description) {
    //     const lines = description.split('.');
    //     const bulletPoints = lines.map((line) => {
    //       const trimmedLine = line.trim();
    //       if (trimmedLine.includes('*')) {
    //         return `<li>${trimmedLine}</li>`;
    //       }
    //       return trimmedLine || '';
    //     });
    //     const markdownContent = bulletPoints.join('\n');
    //     return marked(markdownContent, { sanitize: true });
    //   }
    //   return '';
    // },
    renderMarkdown(markedText) {
      if (markedText) {
        return marked(markedText, {sanitize: true})
      }
      return '';
    },
    bookRoom(room, rate, roomIndex, rateIndex, source) {
      if(source === "book_room_unit" && this.enable_room_unit_booking){
        this.booking_room_unit = true // required for the loader
        this.selectedRoomPayload = {room, rate, activeTab: this.roomIndex, rateIndex}
        this.showRoomUnitModal = true
      }
      else if(source === "book_room") {
        this.booking_room_unit = false // required for the loader
        this.goNext({room, rate, roomIndex, rateIndex}, null)
      }
    },
    closeRoomUnitModal(payload){
      // if there was any room selected, its id will come as payload, otherwise we just close the modal
      if(payload){
        this.showRoomUnitModal = false
        this.selectedRoomPayload.room.room_id = payload.room_id
        this.selectedRoomPayload.room.room_unit_number = payload.room_unit_number
        this.goNext(this.selectedRoomPayload)
      }

      this.showRoomUnitModal = false
      this.selectedRoomPayload = {}
    },
    goNext(args){
      if(args.rate.price_summary.total_points && this.enable_book_with_points){
        let points = args.rate.price_summary.total_points
        args.points = points
      }
      this.$emit('bookRoom', args)
    },
    showRemoveBtn(room, rate) {
      let currentRoom = this.selected_room_details.find(room => room.room_no === this.roomIndex)
      return currentRoom?.hasOwnProperty('package_id') ?
        currentRoom?.room_type_id === room.room_type_id && currentRoom?.rate_code_id === rate.id && currentRoom.package_id === rate?.package_id :
        currentRoom?.room_type_id === room.room_type_id &&
        currentRoom?.rate_code_id === rate.id &&
        !rate.hasOwnProperty('package_id')
    },
    preferredRoom(room) {
      if (this.$route.query.hasOwnProperty('rn_code')) {
        return this.$route.query.rn_code === this.roomDetails(room).code
      }
    },
    packageRateDetails(obj) {
      return this.packages[this.roomIndex].find(pack => pack.id === obj.package_id)
    },
    showMore(room) {
      return room.showMore && this.description(room) && this.description(room).length > this.descMaxLength
    },
    showLess(room) {
      return !room.showMore && this.description(room) && this.description(room).length > this.descMaxLength
    },
    description(room) {
       const description = this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.description, 'ROOM_TYPES', this.roomDetails(room).id, 'description') || '';
       const additionalDetails = this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.additional_details, 'ROOM_TYPES', this.roomDetails(room).id, 'additional_details') || '';

      return `${description}<br/><br/>${additionalDetails}`;
    },
    packageCharges(charges) {
      return charges.reduce((b, a) => b + a.net_value_excl_taxes, 0);
    },
    rateCodeDetails(item) {
      if (item.hasOwnProperty('package_id')) {
        item.package_description  = `${(this.packages[this.roomIndex].find(pack => pack.id === item.package_id).description || '')}`
        item.additional_details = `${(this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id).additional_details || '')}`
        item.description = `${(this.packages[this.roomIndex].find(pack => pack.id === item.package_id).description || '')}`
        item.package_code = this.packages?.[this.roomIndex]?.find(pack => pack.id == item.package_id)?.code
        return item
      }
      return this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id)
    },
    roomDetails(item) {
      if (item.hasOwnProperty('package_id')) {
        return item
      }
      return this.roomTypes[this.roomIndex]?.find(room => room.id === item.room_type_id)
    },
    roomImages(room){
      let room_details = this.roomDetails(room)
      if(!room_details)
        return
      let arr =  [room_details.cover_photo, ...room_details.images.map(i => i.url)]
      return arr
    },
    openRateInfoModal(rate) {
      this.dialogVisible = true
        this.rateData =  {
            package_description: this.translateValues(this.objectCode(rate),this.rateCodeDetails(rate)?.package_description, this.objectType(rate),  this.objectId(rate), 'description'),
            additional_details: this.translateValues(this.objectCode(rate), this.rateCodeDetails(rate)?.additional_details, this.objectType(rate), this.objectId(rate), 'additional_details'),
        }
    },
    formattedPackageDescription(package_description) {
      return `${package_description?.length > 90 ?  `${package_description?.slice(0,89)}...` : package_description}`
    },
    formattedRatePlanDescription(rate_plan_description) {
      return `${rate_plan_description?.length > 90 ?  `${rate_plan_description?.slice(0,89)}...` : rate_plan_description}`
    },
    objectCode(rate) {
      if (this.rateCodeDetails(rate).package_code) {
        return this.rateCodeDetails(rate).package_code
      }
      return this.rateCodeDetails(rate)?.code
    },
    objectType(rate) {
      if (this.rateCodeDetails(rate).package_code) {
        return 'PACKAGE_RATES'
      }
      return 'RATE_CODES'
    },
    objectId(rate) {
      if (this.rateCodeDetails(rate).package_code) {
        return this.rateCodeDetails(rate).package_id
      }
      return this.rateCodeDetails(rate).id
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
      return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";


:deep(ul) {
  margin-left: 30px !important;
  list-style: disc !important;
}

.preferred-tag {
  background-color: $hk-blue;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: normal;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.room-desc {
  font-size: 14px;
  width: 90%;
  // text-align: justify;
  // border: solid #dadada 1px;
  // border-radius: 5px;
  // padding: 10px;
  .show-more{
    color: $hk-primary-color;
  }

}

.info-icon {
  margin-left: 5px;
}

@include media('>=desktop') {

  .wrapper {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .room-wrapper {
      border: solid #dadada 1px;
      border-radius: 5px;
      padding: 16px;
      margin-top: 10px;
      display: flex;
    }

    .room {
      width: 30%;
      .carousel-container{
        width: 90%;
        height: 180px;
      }
      .desc {
        padding: 0 2px;
        width: 90%;
        text-align: justify;
        overflow: auto;
        max-height: 250px;
      }
    }

    .available-rates {
      flex: 1;

      .rates-wrapper {
        border: 1px solid #dadada;
        border-radius: 5px;
        margin: 10px 0;

        .rates {
          margin: 10px 0;

          .rate {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            .rates-desc {
              align-self: start;
              width: 70%;

              .description {
                padding: 0 2px;
                width: 100%;
                // text-align: justify;
                // overflow: auto;
                // max-height: 250px;
                font-size: 14px;
                font-weight: 400 !important;
                font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, 'Lato', Arial, sans-serif;
              }

              .price {
                font-size: 16px;
              }

            }

            .discount-price {
              font-size: 10px;
              margin-right: 10px;
              text-decoration: line-through red;
            }
            .base-amount-before-tax {
              font-size: 11px;
              margin-right: 10px;
              text-decoration: line-through red;
            }

            .desc {
              text-align: end;
              .price {
                font-size: 16px;
              }

              .book-btn {
                margin-top: 20px;
                min-width: 148px;
                .or-divider{
                  display: flex;
                  justify-content: space-around;
                  font-size: 12px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                }
              }

              .price-desc {
                font-size: 12px;
                font-weight: 400;
              }
            }
          }

          .divider {
            margin: 10px;
          }

          &:not(:last-child) {
            .divider {
              border-bottom: 1px #dadada solid;
            }
          }
        }
      }
    }
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #4E4E4E;
}

.sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #4E4E4E;
  display: flex;
  align-items: center;
}

@include media('<=desktop') {
  .room-desc{
    width:100%;
  }
  .title {
    margin: 0px 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
  }

  .wrapper {
    flex-direction: column;
    font-size: 14px;
    padding: 0 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    margin-bottom: 20px;

    .room-wrapper {
      border: solid #dadada 1px;
      border-radius: 5px;
      padding: 16px;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      margin: 15px 25px;
    }

    .room {
      .desc {
        text-align: justify;
      }
    }

    .available-rates {
      .title {
        margin-top: 10px;
      }

      .rates-wrapper {
        .rates {
          .rate {
            margin: 5px 0;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            // background-color: #fcfcfc;

            .rates-desc {
              .price {
                font-size: 16px;
              }
            }

            .discount-price {
              font-size: 10px;
              margin-right: 10px;
              text-decoration: line-through red;
            }

            .base-amount-before-tax {
              font-size: 11px;
              margin-right: 10px;
              text-decoration: line-through red;
            }

            .desc {
              margin: 10px 0;

              .price {
                font-size: 16px;
              }

              .book-btn {
                margin-top: 20px;
              }
            }
          }

          .divider {
            margin: 15px 0px;
          }

          &:not(:last-child) {
            .divider {
              // border-bottom: 1px #dadada solid;
            }
          }
        }
      }
    }
  }
  .mobile-btn {
    height: 50px !important;
  }
}
</style>

