export default {
  select_room_loading: false,
  api_fail: false,
  mobileView: false,
  applicablePackage: null,
  rate_content: {},
  stay_details: null,
  search_data: null,
  rate_details: [],
  packages: [],
  room_types: [],
  rate_codes: [],
  room_type_amenity_groups: [],
  bed_type_groups: [],
  package_charge_types: [],
  rwa_data: null,
  discount_applied: null,
  selected_room_details: [],
  allFilters: [],
  logged_in_user_details: null,
  guest_data: null,
  confirmed_rooms_data: null,
  skip_search: true,
  searchReservationResult: {},
  chosenAddon: [],
  loading: false,
  steps: null,
  current_step: null,
  guest_information: null,
  booking_data: {
    sessions: [],
  },
  booking_color: '#19a7f3',
  booking_page_layout: 'HORIZONTAL',
  access_data: false,
  profile: null,
  editStayDetails: false,
  group_no: null,
  keyFromUrl: null,
  tokenFromUrl: null,
  childComponentKey: 0,
  company_code: null,
  mop: null,
  enable_book_with_points: false,
  show_payment_frame: false,
  children_ages: [],
  disableSearchButton: false
,
  clicked_rates: [],
  clicked_rooms: [],
  queryParamsInfoState: null,
  number_of_rooms: 0,
  country_code: null,
  multi_lingual_dynamic_fields: null,
  localization_language: null,
  localization_currency: null,
  transportation_informations: null,
  selected_dates: null,
  rooms_loader: true
  
}
