import api_to_handle_400 from "./api_to_handle_400.js";
import router from "../routes/router";
import store from "../store";

class AwsWafEngine {
  /**
   * Invokes a GET api call
   * @param url: String
   * @param config: Object, any additional config except the global ones - optional, defaults to {}
   * @return Promise
   * */
  get(url, config) {
    return this.fetchData("GET", url, null, config);
  }

  /**
   * Invokes a POST api call
   * @param url: String
   * @param payload: Object, api call body - optional, defaults to {}
   * @param config: Object, any additional config except the global ones - optional, defaults to {}
   * @return Promise
   * */
  post(url, payload, config) {
    return this.fetchData("POST", url, payload, config);
  }

  /**
   * Invokes a PUT api call
   * @param url: String
   * @param payload: Object, api call body - optional, defaults to {}
   * @param config: Object, any additional config except the global ones - optional, defaults to {}
   * @return Promise
   * */
  put(url, payload, config) {
    return this.fetchData("PUT", url, payload, config);
  }

  /**
   * Invokes a DELETE api call
   * @param url: String
   * @param config: Object, any additional config except the global ones - optional, defaults to {}
   * @return Promise
   * */
  delete(url, config) {
    return this.fetchData("DELETE", url, config);
  }

  /**
   * Invokes any api call
   * @param method, fetch method, can be one of: "GET", "POST", "PUT", "DELETE"
   * @param url: String
   * @param payload: Object, api call body, (has to be null for GET and DELETE) - optional, defaults to {}
   * @param config: Object, any additional config except the global ones - optional, defaults to {}
   * @return Promise
   * */
  fetchData(method, url, payload, config) {
    return new Promise((resolve, reject) => {
      let fetchBody = {
        method,
        ...this.headerConfigs(),
        headers: {
          "Content-Type": "application/json",
          ...config?.headers,
          ...this.headerConfigs(),
        },
        ...config
      }
      if (payload && !['GET', 'DELETE'].includes(method)) fetchBody.body = JSON.stringify(payload)
      AwsWafIntegration.fetch(this.completeUrl(url, config), fetchBody)
        .then(async (response) => {
          if (!response.ok) {
            let msg = await response.text()
            return Promise.reject({ response, data: { message: msg }, status: response.status })
          }
          if (response.status >= 200 && response.status < 300) {
            let data = await response.json()
            let res = { response, data, status: response.status }
            this.onSuccess(res)
            resolve(res)
          }
        })
        .catch((error) => {
          this.onError(error)
          reject(error)
        })
    })
  }

  /**
   * Adds the non-null parameters to url
   * @param url: String
   * @param config: Object - optional, defaults to {}
   * */
  completeUrl(url, config) {
    if (!config?.params) return url;

    let filteredParams = {}
    Object.keys(config.params)
      .filter((key) => config.params[key] !== null && typeof config.params[key] !== undefined)
      .forEach((key) => (filteredParams[key] = config.params[key]))
    return url + '?' + new URLSearchParams(filteredParams).toString()
  }
}

class AwsWafHandler extends AwsWafEngine {
  /**
   * @inheritDoc
   * */
  headerConfigs() {
    return {};
  }

  /**
   * @inheritDoc
   * */
  onSuccess(response) {
    // add anything that needs to happen on all successful api calls here
  }

  /**
   * @inheritDoc
   * */
  onError(error) {
    console.log({ error });
    // add anything that needs to happen on all failed api calls here
    const response = error.response;
    if (
      (response?.status === 400 || response?.status >= 500) &&
      !api_to_handle_400.apis.some((item) => response.url.includes(item)) &&
      !router?.history?.current?.name?.includes("search-reservation")
    ) {
      store.commit("SET_ERROR", {
        message: "Something went wrong. Please try again later",
        status: error.response.status,
      });
    }
  }
}

// to keep a Singleton instance
export default new AwsWafHandler();
