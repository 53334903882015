import * as CryptoJS from "crypto-js";

export function SecurityManagerService() {
  var SecurityManager = {
    username: null,
    key: null,
    app_id: "ADMIN",
    app_version: "4.5.5.2",
    geography: null,
    country: null,
    state: null,
    city: null,
    ip: null,
  };

  var token = "";
  var info = "";

  var service = {
    setCredentials: function (username, password) {
      SecurityManager.username = username;
      SecurityManager.key = CryptoJS.enc.Hex.stringify(
        CryptoJS.SHA256(password),
      );
    },
    setCredentialsWithHashedPassword: function (username, hashedPassword) {
      SecurityManager.username = username;
      SecurityManager.key = hashedPassword;
    },

    setGoogleAuthCredentials(username, googleToken) {
      SecurityManager.username = username;
      SecurityManager.key = "GOOGLE-" + googleToken;
    },

    // Get Token
    getToken: function () {
      return token;
    },

    // Get Info
    getInfo: function () {
      return info;
    },

    generate: function () {
      // Set the username.
      var hash, timestamp, message, plain;

      if (!SecurityManager.key) {
        service.logout();
      }
      // Get Current timestamp
      timestamp = new Date().getTime();

      // Construct the hash body by concatenating the userAgent, username, app_id, app_version.
      message = [
        navigator.userAgent.replace(/:/g, ""),
        SecurityManager.app_id,
        SecurityManager.app_version,
        SecurityManager.username,
        timestamp,
      ].join(":");

      // Hash the message, using the key.
      hash = CryptoJS.HmacSHA256(message, SecurityManager.key);

      // Base64-encode the hash to get the resulting token.
      token = CryptoJS.enc.Base64.stringify(hash);

      plain = [
        navigator.userAgent.replace(/:/g, ""),
        SecurityManager.app_id,
        SecurityManager.app_version,
        SecurityManager.username,
        timestamp,
      ].join(":");

      info = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plain));

      return {
        token: token,
        info: info,
        message: message,
        plain: plain,
        hashed_password: SecurityManager.key,
      };
    },

    logout: function () {
      SecurityManager.username = null;

      SecurityManager.key = null;
    },
  };
  return service;
}

export function getToken(username, password) {
  var digest = new SecurityManagerService();
  digest.setCredentials(username, password);
  let tokenOb = digest.generate();

  return {
    token: tokenOb.token,
    info: tokenOb.info,
    hashed_password: tokenOb.hashed_password,
  };
}

export function getTokenWithHashedPass(username, hashedPass) {
  var digest = new SecurityManagerService();
  digest.setCredentialsWithHashedPassword(username, hashedPass);
  let tokenOb = digest.generate();

  return {
    token: tokenOb.token,
    info: tokenOb.info,
    hashed_password: tokenOb.hashed_password,
  };
}

export function getGoogleToken(username, googleToken) {
  let digest = new SecurityManagerService();
  digest.setGoogleAuthCredentials(username, googleToken);
  let tokenOb = digest.generate();

  return {
    token: tokenOb.token,
    info: tokenOb.info,
    hashed_password: tokenOb.hashed_password,
  };
}
