<template>
  <div v-loading="loadingComponent">
    <div class="filters">
      <div class="date-filter">
        <DateFilter :key="localization_language" :searchData="searchData" @onDateSelected="onDateSelected"/>
      </div>
      <div class="rooms">
        <RoomCountPicker @selectRoomCount="selectRoomCount"/>
      </div>
      <div class="guests">
        <GuestsFilter :searchData="searchData" @guestsChanged="guestsChanged" :key="guestKey" />
      </div>
    </div>
    <div class="filters promotion-code m-t-10 flexed justify-between align-center" :class="{'m-t-0' : mobileView}"
         v-if="!showPackage && !showOtherFields('disable_discount_flow')">
      <div class="label" v-if="!mobileView">{{ $t("lbl_have_a_promo_code") }}?</div>
      <div :class="{'w-50' : !mobileView, 'w-100' :mobileView}">
        <el-input @input="handlePromotionCodeChange" @keydown.enter="search" type="text" :placeholder="$t('lbl_enter_promo_code')" @keyup.native="company_code='', group_no='' " @keypress.native="company_code='', group_no=' ' " v-model="promotion_code"/>
      </div>
    </div>
    <div class="filters promotion-code m-t-10 flexed justify-between align-center" :class="{'m-t-0' : mobileView}"
         v-if="showOtherFields('enable_company_booking')">
      <div class="label" v-if="!mobileView">{{$t('lbl_search_by_company')}}</div>
      <div :class="{'w-50' : !mobileView, 'w-100' :mobileView}">
        <el-input  type="text" :placeholder="$t('lbl_search_by_company')" @keyup.native="promotion_code='', group_no=''" @keypress.native="promotion_code='', group_no=''" v-model="company_code"/>
      </div>
    </div>
    <!-- enable_group_member_reservation_booking -->
    <div class="filters promotion-code m-t-10 flexed justify-between align-center" :class="{'m-t-0' : mobileView}"
          v-if="showOtherFields('enable_group_member_reservation_booking')">
      <div class="label" v-if="!mobileView">{{ $t("lbl_enter_group_code") }}</div>
      <div :class="{'w-50' : !mobileView, 'w-100' :mobileView}">
        <el-input @keydown.enter="search"  @keyup.native="promotion_code='', company_code=''" @keypress.native="promotion_code='', company_code=''" type="text" clearable :placeholder="$t('lbl_enter_group_code')" v-model="group_no"/>
      </div>
    </div>
    <div class="discount-list-restrictions"
         v-if="discount_errors && discount_errors.length > 0">
      <ul>
        <li v-for="(error, index) in discount_errors" :key="index">{{ error.text }}</li>
      </ul>
    </div>
    <div class="btn">
      <div class="button">
        <el-button  :loading="btnLoading" @click="search" type="primary" v-if="!showPackage">
          {{ $t("lbl_search") }}
        </el-button>
      </div>
    </div>
    <packages :searchData="searchData" :mobileView="mobileView"
              v-if="showPackage && !loading"/>
     <router-view @close="$router.go(-1)" @onConfirm="onConfirmPaymentMethod"></router-view>
  </div>
</template>

<script>
import DateFilter from "../component/filters/date-filter";
import moment from "moment";
import dayjs from "dayjs";
import {mapActions, mapMutations, mapState} from "vuex";
import GuestsFilter from "../component/filters/GuestsFilter";
import RoomCountPicker from "../component/filters/RoomCountPicker";
import Packages from './Packages.vue';
import ApiCalls from '@/services/api-calls.service.js'

export default {
  name: "Search",
  components: {
    RoomCountPicker,
    GuestsFilter,
    DateFilter,
    Packages
  },
  created() {
    this.loading = true;
    this.searchData.checkIn = moment(this.business_date).toDate()
    this.searchData.checkOut = moment(this.business_date).add(1, 'd').toDate()
    const route_queries = this.$route.query;
    if (route_queries.from) this.searchData.checkIn = moment(route_queries.from).toDate()
    if (route_queries.to) this.searchData.checkOut = moment(route_queries.to).toDate()
    this.searchData.rooms[0].checkIn = this.searchData.checkIn
     this.searchData.rooms[0].checkOut = this.searchData.checkOut
    if (route_queries.guest && parseInt(route_queries.guest)) this.searchData.rooms[0].persons.adults = parseInt(route_queries.guest)
    if (route_queries.guests && parseInt(route_queries.guests)) this.searchData.rooms[0].persons.adults = parseInt(route_queries.guests)
    if (route_queries.children && parseInt(route_queries.children)) this.searchData.rooms[0].persons.children = parseInt(route_queries.children)
    if (route_queries.discount_type) this.promotion_code = route_queries.discount_type
    this.showPackage = !!route_queries.package_type

    if(route_queries.room_count && route_queries.room_count > 1) {
      if(route_queries.guest) this.guestCount = this.searchData.rooms[0].persons.adults
      if(this.searchData.rooms[0].persons.children) this.childrenCount = this.searchData.rooms[0].persons.children
      if (route_queries.room_count) this.roomCount = +route_queries.room_count;
      this.distributeGuests()
    }


    this.SET_SEARCH_DATA(this.searchData);
    if ((this.$route.query.skip_search && this.skip_search)|| this.editStayDetails) {
      this.search()
      this.SET_SKIP_SEARCH(false);
    }
    this.loading = false
    this.SET_DISCOUNT(null)
    this.SET_GUEST_DATA(null)
  },
  data() {
    return {
      showPackage: false,
      btnLoading: false,
      promotion_code: null,
      group_no: null,
      group_codes: null,
      guestKey: 1,
      loading: false,
      loadingComponent: false,
      persons: {
        adults: 1,
        children: 1
      },
      searchData: {
        rooms: [
          {
            persons: {adults: 1, children: 0, children_ages: []},
            checkIn: moment().toDate(),
            checkOut: moment().add(1, 'd').toDate()
          },
        ],
        checkIn: moment().toDate(),
        checkOut: moment().add(1, 'd').toDate(),
        // children_ages: []
      },
      company_code: '',
      showConfirmPaymentMethod: false,
      roomCount: null,
      guestCount: null,
      childrenCount: null,
      firstTimeRoomCount: false
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      search_data: state => state.search_data,
      mobileView: state => state.mobileView,
      skip_search: state => state.skip_search,
      discount_errors: state => state.configs.discount_errors,
      business_date: state => state.property.details.business_day.date.split('T')[0],
      current_step: state => state.current_step,
      propertyDetails: state => state.property.details,
      editStayDetails: state => state.editStayDetails,
      children_ages: state => state.children_ages,
      enable_multi_room_booking: state => state.property.details.website_config?.enable_multi_room_booking,
      localization_language: state => state.localization_language
    }),
    adultsAndChildren() {
      let allRoomsAdults = this.searchData.rooms.reduce((b, a) => b + a.persons.adults, 0)
      let allRoomsChildren = this.searchData.rooms.reduce((b, a) => b + a.persons.children, 0)
      return `${allRoomsAdults} Adults, ${allRoomsChildren} Children`
    },
    handlePromotionCodeChange() {
      if(!this.promotion_code) {
        this.SET_DISCOUNT_ERRORS([])
      }
    }
    },
  methods: {
    ...mapMutations({
      CHANGE_STEP: 'CHANGE_STEP',
      SET_SEARCH_DATA: 'SET_SEARCH_DATA',
      SET_SKIP_SEARCH: 'SET_SKIP_SEARCH',
      SET_ERROR: 'SET_ERROR',
      SET_DISCOUNT: 'SET_DISCOUNT',
      RESET_STATE: 'RESET_STATE',
      SET_GUEST_DATA: 'SET_GUEST_DATA',
      SET_DISCOUNT_ERRORS: 'configs/SET_DISCOUNT_ERRORS',
      WIZARD_LOADING: 'WIZARD_LOADING',
      SET_GROUP_NO: 'SET_GROUP_NO',
      SET_COMPANY_CODE: 'SET_COMPANY_CODE',
      SET_MOP: 'SET_MOP',
      SET_CHILDREN_AGES: 'SET_CHILDREN_AGES',
      SET_NUMBER_OF_ROOMS: 'SET_NUMBER_OF_ROOMS',
    }),
    ...mapActions({
      GET_DISCOUNT: 'configs/GET_DISCOUNT',
    }),
    async search() {
      if(this.company_code){

        let companyData ={}
        try {
          companyData = (await ApiCalls.get('check-mop', {company_code: this.company_code, property_id: this.$route.query.property_id}, 'enterpriseUrl'))?.data

        } catch (errorCheckMop) {
          console.log({errorCheckMop})
          this.$notify.error({title: this.$t('lbl_error'), message: this.$t('lbl_comapny_not_found')})
          return
        }
        if (companyData?.company_pays == true || companyData?.company_pays == 'true') this.$router.push({name: 'confirm-payment-method'}).catch(()=>{})
        else  this.nextStep('GUEST')
        return
      }
      else{
        this.nextStep()
      }

    },
    onConfirmPaymentMethod(mop){
      this.nextStep(mop)
    },
    async nextStep(mop){
      this.RESET_STATE()
      this.SET_MOP(mop)
      this.SET_GROUP_NO(this.group_no)
      this.SET_COMPANY_CODE(this.company_code);
      if (this.promotion_code) {
        this.checkDiscount()
      } else {
        await this.CHANGE_STEP('rooms');
        this.btnLoading = false
      }
    },
    async checkDiscount() {
      let params = {
        start_date: dayjs(this.search_data.checkIn).format('YYYY-MM-DD'),
        end_date: dayjs(this.search_data.checkOut).format('YYYY-MM-DD'),
        code: this.promotion_code.toUpperCase(),
      }
      this.WIZARD_LOADING(true);
      this.GET_DISCOUNT(params)
        .then( async ({data}) => {
          let discount = {name: this.promotion_code.toUpperCase(), id: data[0].discount_id}
          this.SET_DISCOUNT(discount);
          await this.CHANGE_STEP('rooms');
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 500) {
            this.SET_ERROR(false)
            this.$notify.error({
              title: this.$t('lbl_error'),
              message: this.$t('lbl_no_group_code_found')
            })

            if (this.group_codes) {
              this.SET_DISCOUNT_ERRORS([{text: this.$t('lbl_no_valid_discount_found')}])
            }

          } else {
            if (this.group_codes) {
              this.SET_DISCOUNT_ERRORS([{text: this.$t('lbl_no_valid_discount_found')}])
            } else {
              if (error.response.data.length > 0) {
                this.SET_DISCOUNT_ERRORS(error.response.data.filter(e => !e.validation_status))
              } else {
                this.SET_DISCOUNT_ERRORS([{text: this.$t('lbl_no_valid_discount_found')}])

              }
            }

            this.SET_ERROR(false);
          }
        })
        .finally(() => {
          this.btnLoading = false
          this.WIZARD_LOADING(false);
        })

    },
    onDateSelected(selectedDates) {
      this.searchData.checkIn = selectedDates.checkInDate
      this.searchData.checkOut = selectedDates.checkOutDate
      this.searchData?.rooms?.forEach(element => {
        element.checkIn = selectedDates.checkInDate
        element.checkOut = selectedDates.checkOutDate
      });
      this.SET_SEARCH_DATA(this.searchData)
    },
    guestsChanged(payload) {
      this.searchData.rooms?.forEach((element, index) => element.persons = payload[index].persons)
      //this.searchData.rooms = payload
      // if(this.propertyDetails.application_configuration.enable_children_ages) {
      //   this.searchData.children_ages = item.children_ages
      // }
      this.SET_SEARCH_DATA(this.searchData)
    },
    selectRoomCount(newValue) {
      if(!this.enable_multi_room_booking) {
        if(this.roomCount) {
          this.searchData.rooms.push({persons: {adults: 1, children: 0, children_ages:[]}, checkIn: this.searchData.checkIn, checkOut: this.searchData.checkOut})
          if (!this.firstTimeRoomCount) {
            this.searchData.rooms.pop();
            this.firstTimeRoomCount = true;
          }
          this.SET_SEARCH_DATA(this.searchData)
          this.guestKey++
        }
        else {
          this.searchData.rooms = []
          let arr = Array.apply(null, {length: newValue}).map(Number.call, Number)
          arr.forEach(() => {
            this.searchData.rooms.push({persons: {adults: 1, children: 0, children_ages:[]}, checkIn: this.searchData.checkIn, checkOut: this.searchData.checkOut})
          })
          this.SET_SEARCH_DATA(this.searchData)
          this.guestKey++

        }
      }
      else {
        this.SET_NUMBER_OF_ROOMS(newValue)
      }

    },
    showOtherFields(field){
      return this.propertyDetails?.website_config[field]
    },
    distributeGuests() {
      if (this.guestCount && this.roomCount) {
        const isGreater = ((this.guestCount + (this.childrenCount ? this.childrenCount : 0)) / (this.roomCount || 1));
        if (isGreater > this.propertyDetails?.website_config?.max_guests_allowed) {
          this.$notify.info({
            title: this.$t('lbl_info'),
            message: this.$t('lbl_max_occupancy_exceed')
          });
          // this.guestCount = ((this.roomCount || 1) * this.propertyDetails?.website_config?.max_guests_allowed ) - 2
          // this.childrenCount = 2
        }
        // let maxN = (this.roomCount * this.propertyDetails?.website_config?.max_guests_allowed )
        // while((this.guestCount + this.childrenCount) > maxN) {

        //   if((this.guestCount + this.childrenCount) > maxN) {
        //     this.guestCount--;
        //   }
        //   if((this.guestCount + this.childrenCount) > maxN) {
        //     this.childrenCount--;
        //   }

        // }


        const rooms = [];
        for (let i = 0; i < (this.roomCount || 1); i++) {
          rooms.push({ adults: 0, children: 0 });
        }

        const guestsPerRoom = Math.floor(this.guestCount / (this.roomCount || 1));
        const childrenPerRoom = Math.floor(this.childrenCount / (this.roomCount || 1));


        const remainingChildren = this.childrenCount % (this.roomCount || 1);
        const remainingGuests = this.guestCount % (this.roomCount || 1);



        for (let i = 0; i < (this.roomCount || 1); i++) {
          rooms[i].adults = guestsPerRoom + (i < remainingGuests ? 1 : 0);
          rooms[i].children = childrenPerRoom + (i < remainingChildren ? 1 : 0);

        }


        this.searchData.rooms = rooms.map((guests, index) => ({
          persons: {
            adults: guests.adults > 0 ? guests.adults : 1,
            children: guests.children ? guests.children : 0,
            children_ages: []
          },
          checkIn: this.searchData.checkIn,
          checkOut: this.searchData.checkOut
        }));
      }
    },
  },
  beforeMount() {
    this.SET_NUMBER_OF_ROOMS(1)
    this.SET_CHILDREN_AGES([])
  },
  mounted() {
    let getUrl = window.location.href;

    let gtagPayload = {
      app_name: 'hk-booking-web',
      screen_url: getUrl
    }
    
    if(window.dataLayer && typeof window.gtag === 'function') { 
      gtag('event', 'book_intent', gtagPayload);
      console.log('Event: book_intent', gtagPayload)
    }

    if(window.dataLayer && this.propertyDetails?.website_config?.google_analytics_ids?.startsWith("GTM")) {
      gtagPayload.event = "book_intent"
      window.dataLayer.push(gtagPayload)
      console.log('Event: book_intent', gtagPayload)
    }
  }
}
</script>
<style lang="scss">
.rooms {
  .el-select {
    width: 100%;

    .el-input {
      .el-input__inner {
        height: 55px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../../assets/sass/config/variables";
@import "../../../assets/sass/config/mixins";



.filters {
  width: 70%;
  border: solid #dadada 1px;
  border-radius: 5px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .date-filter {
    flex: 3;
  }

  .rooms {
    flex: 1;
    // margin-left: 10px;
  }

  .guests {
    flex: 2;
  }
}
.promotion-code {
  .label {
    font-size: 14px;
  }
}
.btn {
  width: 70%;
  margin: 10px auto;
  display: flex;
  justify-content: flex-end;
}
.discount-list-restrictions {
  width: 70%;
  margin: 10px auto;
  font-size: 1.1rem;

  li {
    &::before {
      content: "•";
      color: #4caf4c;
      margin-right: 8px;
    }
  }
}
@include media('<=1024px') {
  .filters {
    width: 100% !important;
  }
}
@include media('<=tablet') {
  .filters {
    padding: 15px;
    flex-direction: column;
    width: 100%;
    border: none;
    padding-bottom: 0px;
    padding-top: 10px;
    margin: 0;

    .date-filter {
      width: 100%;
    }

    .rooms {
      // margin-top: 10px;
    }

    .guests {
      width: 100%;
    }
  }
  .btn {
    width: 100%;
    padding: 0 15px;
    margin:0px;
    margin-top:0px;
    .button {
      margin-top: 10px;
      width: 100%;
      button {
        width: 100%;
        height: 55px;
      }
    }
  }
  .promotion-code{
    ::v-deep .el-input__inner{
      height: 53.5px !important;
    }
  }
  .hk-dropdown-wrapper {
    ::v-deep .activator::after{
      height: 0px !important;
    }
  }
}




</style>
