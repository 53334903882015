<template>
  <div v-if="selected_room_details && selected_room_details.length > 0">
    <div :class="{'p-t-5': true, 'card': mobileView}" v-for="(room_detail, rIndex) in selected_room_details" :key="rIndex">
      <div v-if="!mobileView">{{ enable_multi_room_booking ? (number_of_rooms > 1 ? `${number_of_rooms} ${$t('lbl_rooms')}` : `1 ${$t('lbl_room')}`) : `${$t('lbl_room')} ${room_detail.room_no + 1 }` }}</div>
      <div :class="[{'border-top': !mobileView}, 'm-b-10', 'p-t-10', 'bold']">{{ translateValues(room_detail.room.code, room_detail.room.name, 'ROOM_TYPES', room_detail.room.id, 'name') + (room_detail.room_unit_number ? ' - ' + room_detail.room_unit_number : '')}}</div>
      <div class="m-b-5">
        <span>{{ room_detail.check_in_date | formatDate }}</span> -
        <span>{{ room_detail.check_out_date | formatDate }}</span>
      </div>
      <div class="m-b-10">
        <span class="m-r-5">{{ $t("lbl_adults") }}</span><span class="m-r-5">{{
          room_detail.number_of_adults
        }}</span> |
        <span class="m-r-5">{{ $t("lbl_children") }}</span><span>{{ room_detail.number_of_children }}</span>
      </div>
      <div class="m-t-10 bold">{{ room_detail.rate.display_code ? translateValues(objectCode(room_detail.rate), room_detail.rate.display_code, objectType(room_detail.rate),objectId(room_detail.rate), 'display_code') : translateValues(objectCode(room_detail.rate), room_detail.rate.name, objectType(room_detail.rate), objectId(room_detail.rate), 'name')  }}</div>
      <template>
<!--        <div class="font-large m-b-10 border-top m-t-10 p-t-10">{{ $t("lbl_rate_details") }}</div>-->

<!--        <div class="flexed justify-between m-b-5" v-for="(rateDate, index) in destructedData(room_detail).rateData"-->
<!--             :key="index">-->
<!--          <div>-->
<!--          <span class="lbl">{{ rateDate.startDate | formatDate('MMM DD') }} {{-->
<!--              rateDate.endDate ? '-' : ''-->
<!--            }} {{ rateDate.endDate | formatDate('MMM DD') }}</span>-->
<!--          </div>-->
<!--          <span v-if="discount_applied" class="value">{{ rateDate.net_value_excl_taxes | currency(currencyCode(room_detail)) }}</span>-->
<!--          <span v-else class="value">{{ rateDate.amount | currency(currencyCode(room_detail)) }}</span>-->
<!--        </div>-->
<!--        -->
<!--        <div class="grid  m-t-10">-->
<!--          <span class="bold500">{{ $t("lbl_total_room_amount") }}</span> <span class="value bold">{{-->
<!--            totalAmount(room_detail) | currency(currencyCode(room_detail))-->
<!--          }}</span> {{currencyCode(room_detail)}}-->
<!--          <template v-if="room_detail.profileTaxes.total_redeemed_points">-->
<!--            <span>{{ $t('lbl_total_points_redeemed_web') }}</span>-->
<!--            <span class="value bold">{{ room_detail.profileTaxes.total_redeemed_points | formatBigNumber }}</span>-->
<!--          </template>-->
<!--        </div>-->

        <div class="grid m-t-5" v-for="(addon, index) in addOns(room_detail)" :key="'addon_'+index" v-if="addon.charge_type_label && addon.charge_type_label !== 'undefined'">
          <span class="">{{translateValues(addon.charge_type_code, addon.charge_type_label, 'CHARGE_TYPE', addon.charge_type_id, 'name' )}} </span>
          <span class="value">{{addon.amount | currency(currencyCode(room_detail)) }}</span>
        </div>

        <div class="border-top"></div>
        <div class="breakup-container m-b-10">
          <div class="charges-wrapper flexed flexed-column gap-10">
            <div v-for="(dayCharge, index) in groupedChargesByDate(room_detail)" :key="index">
              <div class="bold">{{ dayCharge.date | formatDate}}</div>
              <div class="breakup-taxes">
                <div class="flexed justify-between">
                  <span class="bold500">{{ $t('lbl_room_rate') }} </span>
                  <span class="value bold500" v-if="discount_applied">{{ dayCharge.net_value_excl_taxes | currency(currencyCode(room_detail))}} </span>
                  <span class="value bold500" v-else>{{ dayCharge.total_charges | currency(currencyCode(room_detail))}} </span>
                </div>
                <div class="flexed justify-between" v-for="(tax, index) in dayCharge.taxes" :key="index">
                  <span>{{ tax.name }}</span>
                  <!-- <span class="value">{{ tax.amount | currency(currencyCode(room_detail)) }}</span> -->
                  <span class="value">{{ taxAmount(room_detail, tax.tax_type_id, dayCharge.date, true) | currency(currencyCode(room_detail)) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <template v-if="groupedChargesByDate(room_detail)?.length > 1">
          <div class="flexed justify-between border-top">
            <span class="bold">{{ $t("lbl_total_room_rent") }}</span>
            <span class="value bold">{{ sumOfGroupedChargesByDate(room_detail) | currency(currencyCode(room_detail)) }}</span>
          </div>
          <div class="grid m-t-5" v-for="tax in allTaxes(room_detail)" :key="tax.tax_type_id">
            <span class="bold">{{ translateValues(tax.tax_type_code, tax.name, 'TAX_TYPE', tax.tax_type_id, 'name' ) }}</span> <span class="value bold">{{ taxAmount(room_detail, tax.tax_type_id) | currency(currencyCode(room_detail)) }}</span>
          </div>
        </template>

        <div class="font-large m-b-10 bold border-top m-t-5 p-t-5 flexed justify-between">
          <span class="bold">{{ $t("lbl_grand_total") }}</span> <span class="value bold">{{ grandTotal(room_detail) | currency(currencyCode(room_detail)) }}</span>
        </div>
        <div class="font-large m-b-10 bold border-top m-t-5 p-t-5 flexed justify-between">
          <span class="bold">{{ $t("lbl_total_in_property_currency") }}</span> <span class="value bold">{{ total_in_property_currency(room_detail) | currency(currencyCodeProperty(room_detail)) }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {generateUUID, sortedObject} from "@/services/utilities.service";
import deepClone from 'clone'
import { translateDynamicContent } from "@/services/utilities.service";
export default {
  name: 'InformationSummary',
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      current_step: state => state.current_step,
      rateDetails: state => state.rate_details,
      roomTypes: state => state.room_types,
      rateCodes: state => state.rate_codes,
      rateContent: state => state.rate_content,
      selected_room_details: state => state.selected_room_details,
      enable_book_with_points: state => state.enable_book_with_points,
      property_currency: state => state.property.property_currency,
      hotel_currency: state => state.property.details.currency,
      enable_multi_room_booking: state => state.property.details.website_config?.enable_multi_room_booking,
      number_of_rooms: state => state.number_of_rooms,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language,
      discount_applied: state => state.discount_applied
    }),
  },
  methods: {
    currencyCode(room_detail){
      return this.hotel_currency || this.property_currency
    },
    currencyCodeProperty(room_detail){
      return room_detail.rate?.currency_code || this.property_currency
    },
    roomTypeName(room_type_id) {
      return this.roomTypes[0].find(r => r.id === room_type_id)?.name
    },
    rateCodeName(rate_code_id) {
      return this.rateCodes[0].find(r => r.id === rate_code_id)?.name
    },
    totalAmount(room_detail) {
      // if(this.enable_multi_room_booking) {
      //   return room_detail.profileTaxes.room_rate_summary.filter(c=>c.charge_type_code === 'RR').reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
      //  }
      //  else {
      //    return room_detail.profileTaxes.charges.filter(c=>c.charge_type_code === 'RR').reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
      //  }
      return room_detail.profileTaxes.charges.filter(c=>c.charge_type_code === 'RR').reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
    },
    addOns(room_detail) {
      let result = []
      let charges;
      // if(this.enable_multi_room_booking) {
      //   charges = room_detail.profileTaxes?.room_rate_summary?.filter(c=>c.charge_type_code != 'RR')
      // }
      // else {
      //   charges = room_detail.profileTaxes?.charges?.filter(c=>c.charge_type_code != 'RR')
      // }
      charges = room_detail.profileTaxes?.charges?.filter(c=>c.charge_type_code != 'RR')
      if(!charges || !charges.length) return []
      try {
        let groupByVar = charges?.reduce((result, item) => ({...result,[item['charge_type_label']]: [...(result[item['charge_type_label']] || []),item,],}),{},);
        Object.keys(groupByVar).forEach( key => {
          let val = groupByVar[key].reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
          let chargeType = charges?.find(item => item.charge_type_label ===  key);
          result.push({charge_type_label: key, amount: val, charge_type_id: chargeType?.charge_type_id, charge_type_code: chargeType?.charge_type_code})
        })
        return result || []
      } catch (error) {
        console.log({error})
        return []
      }
      // return room_detail.profileTaxes?.charges?.filter(c=>c.charge_type_code != 'RR') || []
      // return room_detail.profileTaxes.charges.filter(c=>c.charge_type_code != 'RR').reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
    },
    taxAmount(room_detail, tax_type_id,date,single) {
     
      if(this.discount_applied && single) {
        const singleDateCharges = room_detail.profileTaxes?.charges?.find(
          (charge) => charge.date === date
        );
        const singleTaxAmount =  singleDateCharges?.taxes?.find((tax) => tax.tax_type_id == tax_type_id)?.amount
        const singleDateAdjustments = singleDateCharges.adjustments; 

        const allAdjustments = singleDateAdjustments.reduce((merged, adjustment) => {
          return { ...merged, ...adjustment }; 
        }, {});

        const mergedTaxes = [].concat(...Object.values(allAdjustments.taxes));

        const adjustmentTaxTotal = mergedTaxes
          .filter(tax => tax.tax_type_id === tax_type_id)
          .reduce((sum, tax) => sum + tax.amount, 0);
          console.log(adjustmentTaxTotal); 

          return (singleTaxAmount + (adjustmentTaxTotal || 0))
      }
      
      if(this.discount_applied) {
          let taxValue = room_detail.profileTaxes.charges.reduce((b, a) => (b || 0) + (a.taxes?.find(t => t.tax_type_id === tax_type_id)?.amount || 0), 0)
          let adjustmentTaxes = room_detail.profileTaxes.charges?.map(item => item.adjustments)?.flatMap(item => item)?.flatMap(item => item.taxes);
          let filteredAdjustementTaxes = adjustmentTaxes?.filter(item => item.tax_type_id === tax_type_id)
          let adjustmentTaxTotal = filteredAdjustementTaxes?.reduce((acc, current) => acc + current?.amount, 0);

          // adjustmentTaxTotal is negative number
          return taxValue + (adjustmentTaxTotal || 0)
        }

        if(single) {
          // get amount for single tax type
          const singleDateCharges = room_detail.profileTaxes?.charges?.find(
          (charge) => charge.date === date
        );
        return singleDateCharges?.taxes?.find((tax) => tax.tax_type_id == tax_type_id)?.amount
        }

        return room_detail.profileTaxes.charges.reduce((b, a) => (b || 0) + (a.taxes?.find(t => t.tax_type_id === tax_type_id)?.amount || 0), 0)
    },
    grandTotal(room_detail) {
      // if(this.enable_multi_room_booking) {
      //   return room_detail.profileTaxes.room_rate_summary.reduce((b, a) => (b || 0)  + (a.net_value || 0) , 0)
      // }
      // else {
      //   return room_detail.profileTaxes.charges.reduce((b, a) => (b || 0)  + (a.net_value || 0) , 0)
      // }
      // return room_detail.profileTaxes.charges.reduce((b, a) => (b || 0)  + (a.net_value || 0) , 0)
      let data = this.groupedChargesByDate(room_detail)
      return this.sumByField(data, 'net_value')
    },
    total_in_property_currency(room_detail) {
      return room_detail.profileTaxes?.estimated_total
    },
    allTaxes(room_detail) {
      let taxes = []
      // if(this.enable_multi_room_booking) {
      //   room_detail?.profileTaxes?.room_rate_summary?.forEach(charge => {
      //   charge?.taxes?.forEach(tax => {
      //     if (!taxes.some(t => t.tax_type_id === tax.tax_type_id))
      //       taxes.push({tax_type_id: tax.tax_type_id, name: tax.name, tax_type_code: tax.tax_type_code})
      //   });
      // });
      // }
      // else {
      //   room_detail?.profileTaxes?.charges?.forEach(charge => {
      //     charge?.taxes?.forEach(tax => {
      //       if (!taxes.some(t => t.tax_type_id === tax.tax_type_id))
      //         taxes.push({tax_type_id: tax.tax_type_id, name: tax.name, tax_type_code: tax.tax_type_code})
      //     });
      //   });
      // }

      room_detail?.profileTaxes?.charges?.forEach(charge => {
          charge?.taxes?.forEach(tax => {
            if (!taxes.some(t => t.tax_type_id === tax.tax_type_id))
              taxes.push({tax_type_id: tax.tax_type_id, name: tax.name, tax_type_code: tax.tax_type_code})
          });
        });

      return sortedObject(taxes, 'name')
    },
    structureData(data) {
      let mappedValues = []
      let elementToManipulate = null
      let indexOfLastElementCopied = 0
      data.forEach((x, index) => {
        if (elementToManipulate === null) {
          elementToManipulate = {
            id: generateUUID(),
            startDate: x.date,
            endDate: null,
            amount: x.amount,
            net_value_excl_taxes: x.net_value_excl_taxes
          }
        } else if (x.date !== elementToManipulate.startDate
          && x.amount === elementToManipulate.amount) {
          elementToManipulate.endDate = x.date
        } else {
          if (indexOfLastElementCopied !== data.length - 1) {
            mappedValues.push(elementToManipulate)
          }
          elementToManipulate = {
            id: generateUUID(),
            startDate: x.date,
            endDate: null,
            amount: x.amount,
            net_value_excl_taxes: x.net_value_excl_taxes
          }
          indexOfLastElementCopied = index
        }
        if (index === data.length - 1) {
          mappedValues.push(elementToManipulate)
        }
      })
      return mappedValues
    },
    destructedData(room_detail) {
      // if(this.enable_multi_room_booking) {
      //   const rateData = deepClone(room_detail.profileTaxes.room_rate_summary).filter(ch => ch.charge_type_code === 'RR')
      //   const packageData = deepClone(room_detail.profileTaxes.room_rate_summary).filter(ch => ch.charge_type_code !== 'RR')
      //   return {
      //     rateData: this.structureData(rateData),
      //     packageData: this.structureData(packageData)
      //   }
      // }
      // else {
      //   const rateData = deepClone(room_detail.profileTaxes.charges).filter(ch => ch.charge_type_code === 'RR')
      //   const packageData = deepClone(room_detail.profileTaxes.charges).filter(ch => ch.charge_type_code !== 'RR')
      //   return {
      //     rateData: this.structureData(rateData),
      //     packageData: this.structureData(packageData)
      //   }
      // }

      const rateData = deepClone(room_detail.profileTaxes.charges).filter(ch => ch.charge_type_code === 'RR')
        const packageData = deepClone(room_detail.profileTaxes.charges).filter(ch => ch.charge_type_code !== 'RR')
        return {
          rateData: this.structureData(rateData),
          packageData: this.structureData(packageData)
        }
    },
    redeemedPoints(index){
      let selected_room = this.selected_room_details[index]
      let r = this.rateDetails.find(i => !!i.find(item => item.room_type_id === selected_room.room_type_id))
      r = r.find(item => item.room_type_id === selected_room.room_type_id)
      let total_points = r.rate_codes.find(item => item.id === selected_room.rate_code_id)?.price_summary?.total_points
      return total_points
    },
    objectCode(rate) {
      if (rate.package_code) {
        return rate.package_code
      }
      return rate?.code
    },
    objectType(rate) {
      if (rate.package_code) {
        return 'PACKAGE_RATES'
      }
      return 'RATE_CODES'
    },
    objectId(rate) {
      if (rate?.package_code) {
        return rate?.package_id
      }
      return rate?.id
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
      return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    },
    groupedChargesByDate(room_detail) {
      const rateData = deepClone(room_detail.profileTaxes.charges)?.filter(ch => ch.charge_type_code)

      // grouping items by date
      let groupedByDate = this.groupByField(rateData, 'date', 'charges')

      // mapping total charges and total taxes
      return groupedByDate?.map(date => ({
        date: date.date,
        total_charges: date.charges?.reduce((total, charge) => total + charge.amount, 0),
        net_value_excl_taxes: date.charges?.reduce((total, charge) => total + charge.net_value_excl_taxes, 0),
        net_value: date.charges?.reduce((total, charge) => total + charge.net_value, 0),
        // first group taxes by code and that sum them
        taxes: this.groupByField(date.charges.flatMap(d => d.taxes), 'code', 'data')
          ?.map(item => ({
              code: item.code,
              name: item.data[0].name,
              amount: this.sumByField(item.data, 'amount'),
              tax_type_id: item.data[0].tax_type_id
            })),
      }))
    },
    sumOfGroupedChargesByDate(room_detail){
      if(this.discount_applied) {
        return this.sumByField(this.groupedChargesByDate(room_detail), 'net_value_excl_taxes')
      }
      return this.sumByField(this.groupedChargesByDate(room_detail), 'total_charges')
    },
    groupByField(data, key, dataKey){
      return data?.reduce((array, item) => {
        // const date = item.date.split('T')[0];
        // let dateItem = array.find(d => d.date === date)
        // if(!dateItem){
        //   array.push({date, charges: [item]})
        // }else {
        //   dateItem?.charges.push(item)
        // }

        const byKey = item[key]
        let keyItem = array.find(d => d[key] === byKey)
        if(!keyItem){
          let obj = {}
          obj[key] = byKey
          obj[dataKey] = [item]
          array.push(obj)
        }else {
          keyItem?.[dataKey].push(item)
        }
        return array
      }, [])
    },
    sumByField(data, key){
      return data?.reduce((sum, item) => { return sum + item[key]}, 0)
    },
  }
}
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 5px;
  justify-content: space-between;
  .value {
    justify-self: end;
  }
}

.breakup-container {
  max-height: 300px;
  overflow: auto;
}
</style>
