<template>
  <div class="hk-tel-input" :class="classes" v-bind="$attrs">
    <el-select v-model="country_selected_obj"
               :clearable="clearable"
               @clear="filterOptions"
               filterable
               value-key="code"
               :filter-method="filterOptions"
               :placeholder="dropDownPlaceholder"
               :style="elSelectStyle"
               :disabled="disabled"
               :autocomplete="autocomplete"
               :class="elSelectClasses"
               :size="inputSize"
               @change="updateModel">
      <template slot="prefix">
        {{ country_selected_obj ? ((showFlags ? country_selected_obj.flag + '&nbsp;' : '') + getTranslatedCountry(country_selected_obj.name, true)) : prefixText ? prefixText : 'Country Code' }}
      </template>
      <el-option
          v-for="(item) in filteredOptions"
          :key="item.code"
          :label="item.dial_code"
          :value="item"
          :style="elOptionStyle">
        {{ showFlags ? item.flag : '' }} {{ getTranslatedCountry(item.name) }} {{ item.dial_code }}
      </el-option>
    </el-select>
    <div class="fd-form-group">
      <input class="form-field"
             :disabled="disabled"
             autocomplete="new-password"
             :placeholder="placeholder"
             :name="controlName"
             :id="controlName"
             @keypress="isInt($event)"
             v-model="phone_number"
             :style="elInputStyle"
             v-on="inputListeners"
             @input="buildResults()"/>

      <label :for="controlName" class="form-label">
        <span v-if="showAsterisk" class="asterisk">*</span> {{ placeholder }}
      </label>
      <span class="error" v-if="!!error_message">{{ error_message }}</span>

    </div>
  </div>
</template>

<script>
import countryCodes from './country-codes.json'
import {getResultsFromPhoneNumber, getModelNumber} from './controller.js'
import {parsePhoneNumberFromString, findPhoneNumbersInText, getExampleNumber, AsYouType} from 'libphonenumber-js'
// import examples from 'libphonenumber-js/examples.mobile.json'
import {mapState} from 'vuex'
import { isInt } from "@/services/utilities.service";

export default {
  name: 'HkPhoneInput',
  $_veeValidate: {
    name() {
      return this.controlName;
    },
    value() {
      return this.model;
    }
  },
  props: {
    model: {default: null, required: true},
    disabled: {type: Boolean, default: false},
    showFlags: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    prefixText: {type: String, default: null},
    defaultCountryCode: {type: String, default: null},
    dropDownPlaceholder: {type: String, default: 'Select'},
    placeholder: {type: String, default: 'Phone number'},
    elSelectStyle: {type: Object, default: null},
    elSelectClasses: {default: null},
    elOptionStyle: {type: Object, default: null},
    elInputStyle: {type: Object, default: null},
    elInputClasses: {default: null},
    classes: {default: null},
    noMatchText: {type: String, default: 'No data'},
    numberValidation: {type: Boolean, default: true},
    autocomplete: {default: null},
    error: {required: false},
    inputSize: {default: 'medium', required: false},
    controlName: {required: false,  type: String, default: 'controlName'},
    showAsterisk: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  async beforeMount() {
    this.loading = true
    this.countries = await countryCodes
    this.filteredOptions = [...countryCodes]
    await this.init()
    this.loading = false
  },
  data() {
    return {
      countries: null,
      filteredOptions: [],
      country_selected_obj: null,
      phone_number: null,
      results: null,
      loading: false,
      isInt: isInt
    }
  },
  computed: {
    ...mapState({
      property: state => state.property
    }),
    inputListeners() {
      return Object.assign({},
          this.$listeners,
          {
            blur: event => {
              this.$emit('blur', event);
            },
            input: event => {
              this.$emit('input', event)
            }
          }
      )
    },
    /*phoneNumberExample() {
      const {country_selected_obj} = this
      const phoneNumber = country_selected_obj ? getExampleNumber(country_selected_obj.code, examples) : null
      return phoneNumber ? phoneNumber.formatNational() : null
    },*/
    error_message() {
      if (this.phone_number && this.results && !this.results.isValid && this.numberValidation) {
        return this.$t('lbl_not_valid')
      }

      if (this.error) {
        return this.$t('lbl_required')
      }

      return false
    }
  },
  methods: {
    init(changed) {
      if (this.defaultCountryCode && !changed) {
        this.country_selected_obj = this.countries.find(c => c.code == this.defaultCountryCode)
      }
      if (this.model && !changed) {
        const found = getModelNumber(this.model, findPhoneNumbersInText)

        if (found && found.length > 0) {
          this.phone_number = found[0].number.nationalNumber
          this.country_selected_obj = this.countries.find(c => c.code == found[0].number.country)
        } else {
          let incomplete = new AsYouType().input(this.model);

          if (incomplete?.split(' ').length === 1) {
            this.country_selected_obj = this.countries.find(c => c.code == (this.property.country == 'USA' ? 'US' : this.property.country))
            this.phone_number = this.model
          } else {
            let country_code = incomplete.split(' ')[0] === '+1' ? 'US' : false
            if (country_code === 'US') {
              this.country_selected_obj = this.countries.find(c => c.code === country_code)
            } else {
              this.country_selected_obj = this.countries.find(c => c.dial_code === incomplete.split(' ')[0])
            }
            this.phone_number = incomplete.split(' ').filter((item, i) => i > 0).join('')
          }
        }
      }
      if (!this.defaultCountryCode && !this.model && !this.disabled) {
        this.country_selected_obj = this.countries.find(c => c.code == (this.property.country == 'USA' ? 'US' : this.property.country))
        this.updateModel()
      }
      this.$emit('isValid', true)
      // (!this.checkIfRequiredAndNull() && !this.checkIfValidNumber())
      // 'true' is Akash's req.
    },
    updateModel() {
      if (this.phone_number && this.country_selected_obj)
        this.$emit('update:model', this.country_selected_obj.dial_code + this.phone_number)
      else
        this.$emit('update:model', null)
    },
    filterOptions(filterValue) {
      if (filterValue) this.filteredOptions = [...this.countries.filter(option => option.name.toLowerCase().indexOf(filterValue?.toLowerCase()) > -1)]
      else this.filteredOptions = [...this.countries]
    },
    async buildResults() {
      this.loading = true
      this.updateModel()
      if (this.country_selected_obj)
        this.results = await getResultsFromPhoneNumber(this.country_selected_obj.dial_code + this.phone_number,
            this.country_selected_obj.code, parsePhoneNumberFromString)
      this.$emit('isValid', true)
      this.loading = false
      return this.results?.isValid
    },
    getTranslatedCountry(name, substring) {
      let translatedCountry = name
      if(substring) {
        return translatedCountry?.substring(0, 13);
      }
      return translatedCountry
    }
    /*checkIfRequiredAndNull() {
      return this.required && !this.phone_number
    }*/
  },
  watch: {
    model(newValue, oldValue) {
      this.init('modelChanged')
      if (!newValue) {
        this.country_selected_obj = this.countries.find(c => c.code == (this.property.country == 'USA' ? 'US' : this.property.country))
        this.phone_number = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~css_vars";

$primary: var(--primary-color);
$white: #fff;
$gray: #b6b6b6;
$default_input_color: #212121;

.hk-tel-input {
  display: flex;
  position: relative;
  border: 1px solid #e6e6e6;
  height: 45px;
  border-radius: 3px;

  ::v-deep .el-loading-spinner {
    margin-top: -10px;

    svg {
      height: 20px;
    }
  }

  ::v-deep .el-select {
    height: 100%;

    .el-input {
      height: 100%;

      .el-input__inner {
        border-right: 1px solid #e6e6e6;
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        width: 100px;
        padding-left: 20px;
        font-size: .9em !important;
        padding-top: 5px !important;
        height: 100%;

        &:focus {
          border-color: #dcdfe5;
        }
      }
    }

    .el-input__prefix {
      font-size: .8em;
      height: 0;
    }

    .el-input__suffix {
      .el-select__caret {
        font-size: .9em;
      }
    }
  }

  .fd-form-group {
    font-family: "Lato", sans-serif;
    position: relative;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    width: 100%;

    &:focus-within {
      border-color: #e6e6e6;

      .form-field {
        color: $default_input_color
      }
    }

    .required {
      color: $hk-danger;
      font-size: x-small;
    }

    .postfix {
      position: absolute;
      right: 0;
      bottom: 1px;
      top: 0;
      height: 42px;
      border: none;
      border-radius: 0;
      border-left: 1px solid #e6e6e6;
      background-color: #e6e6e6;
      color: #409EFF;
    }
  }

  .form-field {
    font-family: inherit;
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 1.0rem;
    color: $default_input_color;
    padding: 0;
    margin-top: 10px;
    background: transparent;

    &:focus {
      color: $primary;
    }

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form-label {
      font-size: 1rem;
      cursor: text;
      top: 8px;
      padding-bottom: 5px;

      @media (max-width: 1165px) {
        font-size: .7rem;
        top: 12px;
      }

      @media (min-width: 1165px) and (max-width: 1315px) {
        font-size: .8rem;
        top: 10px;
      }
    }
  }

  .form-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.8rem;
    color: $gray;
    padding: 0 0 5px 0;
    .asterisk{
    color: $hk-danger;
  }
  }

  .form-field:focus {
    ~ .form-label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 0.8rem;
      color: $primary;
      font-weight: 400;
      padding: 0 0 5px 0;

      @media (max-width: 1165px) {
        font-size: .7rem;
      }
    }

    font-weight: 400;
  }

  .error {
    position: absolute;
    right: 3px;
    color: #F56C6C;
    font-size: 10px;
  }
}
</style>
