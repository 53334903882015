import dayJs from "dayjs";
import CommonServices from "../services/common.services";
import Clone from "clone";
import Vue from "vue";
import utc from "dayjs/plugin/utc";
import router from "@/routes/router";
import store from "@/store/index";
import deepClone from "clone";
import ApiCalls from "@/services/api-calls.service.js";
import AwsWafHandler from "../services/AwsWafHandler";

export default {
  updateAddons({ state, dispatch, commit }, payload) {
    let isNew = !state.chosenAddon.find(
      (addon) => addon.id === payload.id && addon.room_no === payload.room_no
    );
    let addons = JSON.parse(JSON.stringify(state.chosenAddon));

    if (isNew) {
      addons.push(payload);
    } else {
      let addonToRemove = state.chosenAddon.findIndex(
        (addon) => addon.id === payload.id && addon.room_no === payload.room_no
      );
      addons.splice([addonToRemove], 1);
    }

    commit("SET_RESERVATION_ADDONS", addons);
  },

  setSearchDataFromQueryParams({ state, dispatch, commit }, route_queries) {
    let roomCount = +route_queries.room_count || 1;
    let searchData = {
      ...state.search_data,
    };
    searchData.rooms = [];
    for (let i = 0; i < roomCount; i++) {
      searchData.rooms.push({
        checkIn: state.search_data.checkIn,
        checkOut: state.search_data.checkOut,
        persons: {
          adults:
            state.search_data.rooms?.[i]?.persons?.adults ||
            +route_queries?.guest ||
            +route_queries?.guests ||
            1,
          children:
            state.search_data.rooms?.[i]?.persons.children ||
            +route_queries.children ||
            0,
          children_ages: [],
        },
      });
    }
    commit("SET_SEARCH_DATA", searchData);
  },
  setSearchDataFromExistingReservation(
    { state, dispatch, commit },
    resrvation
  ) {
    let searchData = deepClone(state.search_data);
    searchData.checkIn = dayJs(resrvation.check_in_date.split("T")[0]).toDate();
    searchData.checkOut = dayJs(
      resrvation.check_out_date.split("T")[0]
    ).toDate();
    searchData.rooms = [];
    searchData.rooms.push({
      checkIn: dayJs(resrvation.check_in_date.split("T")[0]).toDate(),
      checkOut: dayJs(resrvation.check_out_date.split("T")[0]).toDate(),
      persons: {
        adults: +resrvation?.adults || +resrvation?.adults || 1,
        children: +resrvation.children || 0,
      },
    });
    commit("SET_SEARCH_DATA", deepClone(searchData));
  },

  addSessions({ state, dispatch, commit }) {
    let guest_data = {
      guest_data: [
        {
          loyalty_guest_id: state.logged_in_user_details.account_number,
        },
      ],
    };
    let sessions = state.profile.sessions;

    try {
      let promises = sessions.map((session) => {
        return CommonServices.addSessions(session, guest_data);
      });
      return Promise.all(promises);
    } catch (e) {}
  },
  async userDetails({ state, dispatch, commit }, payload) {
    const query = router.currentRoute.query;
    let params = {};
    if (query) {
      for (const queryElement in query) {
        if (queryElement !== "url") {
          params[queryElement] = query[queryElement];
        }
      }
    }
    try {
      let userRes = (await ApiCalls.get("website/users", params, "baseAppUrl"))
        ?.data;
      commit("SET_LOGGED_IN_USER_DETAILS", userRes);
    } catch (error) {
      commit("SET_LOGGED_IN_USER_DETAILS", null);
      console.log(error);
    }
  },
  async getAvailableRates({ state, dispatch, commit }, payload) {
    let route_queries = router.currentRoute.query;

    if (route_queries.skip_search) {
      dispatch("setSearchDataFromQueryParams", route_queries);
    }
    if (state.editStayDetails)
      dispatch(
        "setSearchDataFromExistingReservation",
        state.searchReservationResult?.reservation_details
      );

    commit("SET_SELECT_ROOM_LOADER", true);

    let enable_multi_room_booking =
      state.property.details.website_config?.enable_multi_room_booking;
    await dispatch("getBookingProfile", {
      count: enable_multi_room_booking ? 1 : state.search_data.rooms.length,
    });

    if (
      state.editStayDetails &&
      state.property.details.website_config?.enable_loyalty_flow
    ) {
      await dispatch("userDetails");
    }
    if (
      state.logged_in_user_details &&
      state.logged_in_user_details.account_number
    )
      await dispatch("addSessions");

    let rate_details = [],
      packages = [],
      rate_codes = [],
      room_types = [],
      room_type_amenity_groups = [],
      bed_type_groups = [],
      package_charge_types = [],
      allFilters = [],
      rwa_data = null;

    for (let index = 0; index < state.search_data.rooms.length; index++) {
      let params = {
        check_in_date: dayJs(state.search_data.checkIn).format().split("T")[0],
        check_out_date: dayJs(state.search_data.checkOut)
          .format()
          .split("T")[0],
        number_of_rooms: enable_multi_room_booking
          ? state.number_of_rooms
          : state.search_data.rooms.length,
        number_of_persons: state.search_data.rooms[index].persons.adults,
        number_of_children: state.search_data.rooms[index].persons.children,
        children_ages:
          state.search_data.rooms[index].persons?.children_ages?.join(","),
      };

      commit("SET_QUERY_PARAMS_INFO_STATE", params);
      commit("SET_SELECTED_DATES", {
        check_in_date: params.check_in_date,
        check_out_date: params.check_out_date,
      });

      params.discount_id = state.discount_applied && state.discount_applied.id;
      if (state.group_no) params.group_code = state.group_no;
      if (state.company_code) params.company_code = state.company_code;
      if (state.mop) params.mop = state.mop;

      if (payload.rate_code_ids) {
        params.rate_code_ids = payload.rate_code_ids;
      }

      if (state.applicablePackage)
        params.package_id = state.applicablePackage.id;
      else if (route_queries.package_id)
        params.package_id = route_queries.package_id;

      await CommonServices.getRatesWithAvailability(params)
        .then(({ data }) => {
          rate_details.push(data.rate_details);
          packages.push(data.packages);
          rate_codes.push(data.rate_codes);
          room_types.push(data.room_types);
          room_type_amenity_groups.push(data.room_type_amenity_groups);
          bed_type_groups.push(data.bed_type_groups);
          package_charge_types = data.package_charge_types
            ? data.package_charge_types
            : [];
          rwa_data = data;
          allFilters.push({ displayBy: "byRooms", sortByPrice: "asc" });
          if (!state.applicablePackage?.id && route_queries.package_id) {
            let pack = data?.packages?.find(
              (pack) => pack.id === route_queries.package_id
            );
            if (pack) commit("SET_APPLICABLE_PACKAGE", pack);
          }
        })
        .catch((err) => {
          rate_details.push([]);
          rate_codes.push([]);
          packages.push([]);
          room_types.push([]);
          package_charge_types = [];
          rwa_data = null;
          console.log(err);
        });
    }
    commit("SET_RATE_DETAILS", rate_details);
    commit("SET_PACKAGES", packages);
    commit("SET_RATE_CODES", rate_codes);
    commit("SET_ROOM_TYPES", room_types);
    commit("SET_ROOM_TYPE_AMENITY_GROUPS", room_type_amenity_groups);
    commit("SET_BED_TYPE_GROUPS", bed_type_groups);
    commit("SET_PACKAGE_CHARGE_TYPES", package_charge_types);
    commit("SET_RWA_DATA", rwa_data);
    commit("SET_ALL_FILTERS", allFilters);

    await commit("SET_SELECT_ROOM_LOADER", false);
  },

  async getRoomAvailableRates({ state, commit }, payload) {
    let route_queries = router.currentRoute.query;
    commit("SET_SELECT_ROOM_LOADER", true);
    let roomIndex = payload.roomIndex;
    let rate_code_ids = payload.rate_code_ids;
    let searchData = state.search_data.rooms[roomIndex];
    let enable_multi_room_booking =
      state.property.details.website_config?.enable_multi_room_booking;

    let rate_details = Clone(state.rate_details),
      packages = Clone(state.packages),
      rate_codes = Clone(state.rate_codes),
      room_types = Clone(state.room_types),
      room_type_amenity_groups = Clone(state.room_type_amenity_groups),
      bed_type_groups = Clone(state.bed_type_groups),
      allFilters = Clone(state.allFilters),
      package_charge_types = Clone(state.package_charge_types),
      rwa_data = Clone(state.rwa_data);

    let params = {
      check_in_date: dayJs(searchData.checkIn).format().split("T")[0],
      check_out_date: dayJs(searchData.checkOut).format().split("T")[0],
      number_of_rooms: enable_multi_room_booking
        ? state.number_of_rooms
        : state.search_data.rooms.length,
      number_of_persons: searchData.persons.adults,
      number_of_children: searchData.persons.children,
      children_ages: searchData.persons?.children_ages?.join(","),
    };

    commit("SET_SELECTED_DATES", {
      check_in_date: params.check_in_date,
      check_out_date: params.check_out_date,
    });

    commit('SET_QUERY_PARAMS_INFO_STATE', params)

    commit('SET_QUERY_PARAMS_INFO_STATE', params)

    params.discount_id = state.discount_applied && state.discount_applied.id
    if(state.group_no) params.group_code = state.group_no
    if(state.company_code) params.company_code = state.company_code
    if(state.mop) params.mop = state.mop

    if (rate_code_ids) {
      params.rate_code_ids = rate_code_ids;
    }

    if (state.applicablePackage) params.package_id = state.applicablePackage.id;
    else if (route_queries.package_id)
      params.package_id = route_queries.package_id;

    if (state.logged_in_user_details && state.enable_book_with_points) {
      params.book_with_points = true;
    }
    params.session_id = state.profile.sessions[roomIndex];

    await CommonServices.getRatesWithAvailability(params)
      .then(({ data }) => {
        rate_details[roomIndex] = data.rate_details;
        packages[roomIndex] = data.packages;
        rate_codes[roomIndex] = data.rate_codes;
        room_types[roomIndex] = data.room_types;
        room_type_amenity_groups[roomIndex] = data.room_type_amenity_groups;
        bed_type_groups[roomIndex] = data.bed_type_groups;
        package_charge_types = data.package_charge_types
          ? data.package_charge_types
          : [];
        rwa_data = data;
        allFilters[roomIndex] = { displayBy: "byRooms", sortByPrice: "asc" };
        if (!state.applicablePackage?.id && route_queries.package_id) {
          let pack = data?.packages?.find(
            (pack) => pack.id === route_queries.package_id
          );
          if (pack) commit("SET_APPLICABLE_PACKAGE", pack);
        }
      })
      .catch((err) => {
        rate_details[roomIndex] = [];
        packages[roomIndex] = [];
        rate_codes[roomIndex] = [];
        room_types[roomIndex] = [];
        room_type_amenity_groups[roomIndex] = [];
        bed_type_groups[roomIndex] = [];
        package_charge_types = [];
        rwa_data = {};

        console.log(err);
      });

    commit("SET_RATE_DETAILS", rate_details);
    commit("SET_PACKAGES", packages);
    commit("SET_RATE_CODES", rate_codes);
    commit("SET_ROOM_TYPES", room_types);
    commit("SET_ROOM_TYPE_AMENITY_GROUPS", room_type_amenity_groups);
    commit("SET_BED_TYPE_GROUPS", bed_type_groups);
    commit("SET_ALL_FILTERS", allFilters);
    commit("SET_PACKAGE_CHARGE_TYPES", package_charge_types);
    commit("SET_RWA_DATA", rwa_data);

    await commit("SET_SELECT_ROOM_LOADER", false);
  },

  changeRoomData({ state, dispatch, commit }, payload) {
    let searchData = Clone(state.search_data);
    searchData.rooms[payload.roomIndex].checkIn =
      payload.selectedDates.checkInDate;
    searchData.rooms[payload.roomIndex].checkOut =
      payload.selectedDates.checkOutDate;
    commit("SET_SEARCH_DATA", searchData);
  },

  updateSelectedRoom({ state, dispatch, commit }, payload) {
    let selectedRooms = Clone(state.selected_room_details);
    let room_details = payload.room_details;

    if (payload.action === "ADD") {
      selectedRooms.push(room_details);
    } else if (payload.action === "REMOVE") {
      selectedRooms = selectedRooms.filter(
        (room) => room.room_no !== room_details.room_no
      );
    } else if (payload.action === "UPDATE") {
      //
    } else if (payload.action === "REMOVE_ALL") {
      selectedRooms = [];
    }
    commit("SELECTED_ROOM_DETAILS", selectedRooms);
  },

  async createReservation(
    { state, dispatch, commit },
    finallyChangeStepToConfirm
  ) {
    dayJs.extend(utc);
    let booking_data = deepClone(state.booking_data);
    let property = state.property.details;
    let batchId = state.profile.batch_id;
    let selected_room_details = state.selected_room_details;

    // if (state.logged_in_user_details && state.logged_in_user_details.account_number) {
    //   dispatch("addSessions")
    // }else { .... }

    if (state.enable_book_with_points) {
      booking_data.sessions.forEach((session) => {
        session.guest_data[0].loyalty_guest_id =
          state.logged_in_user_details.user_id;
        session.guest_data[0].loyalty_guest_number =
          state.logged_in_user_details.account_number;
        session.guest_data[0].loyalty_program_id =
          state.logged_in_user_details.loyalty_program_id;
      });
    }

    booking_data.transportations = state.transportation_informations;

    const url = `properties/${property.id}/booking-engine/new-batch-booking/${batchId}/commit`;
    commit("WIZARD_LOADING", true);
    try {
      let res = (await ApiCalls.post(url, booking_data, {}, "enterpriseUrl"))
        ?.data;
      let rooms_w_confirmations = selected_room_details.map(
        (room_details, index) => {
          res[index].room_unit_number = room_details.room_unit_number;
          return {
            room_details: room_details,
            confirmation_no: res[index].confirmation_no,
            id: res[index].id,
          };
        }
      );
      commit("SET_CONFIRMED_ROOMS_DATA", res);
      try {
        let discount_applied = state.discount_applied;
        // let selection_for_rooms = state.selection_for_rooms
        state.selected_room_details.forEach((room_details, index) => {
          let folioAndCharges = room_details.profileTaxes;
          let totalRevenue = 0;
          let taxes = 0;
          if (
            folioAndCharges &&
            folioAndCharges.charges &&
            folioAndCharges.charges.length > 0
          ) {
            folioAndCharges.charges.forEach((item) => {
              totalRevenue = totalRevenue + item.net_value_excl_taxes;
              taxes = taxes + (item.net_value - item.net_value_excl_taxes);
            });
          }


          if(window.dataLayer && typeof window.gtag === 'function') {
            let gtagPayload = {
              //     transaction_id: rooms_w_confirmations[index].confirmation_no,
              //     affiliation: "Web Site",
              //     value: Number.parseFloat(totalRevenue).toFixed(2),
              //     tax: Number.parseFloat(taxes).toFixed(2),
              //     // shipping: 5.99,
              //     currency: "USD",
              //     // coupon: "SUMMER_SALE",
              //     items: [
              //     // If someone purchases more than one item,
              //     // you can add those items to the items array
              //      {
              //       item_id: rooms_w_confirmations[index].confirmation_no,
              //       item_name: room_details.room.name,
              //       affiliation: "Web Site",
              //       // coupon: "SUMMER_FUN",
              //       currency: "USD",
              //       price:  Number.parseFloat(totalRevenue).toFixed(2),
              //       quantity: 1
              //     }]
  
              // New google analitic logic
                  transaction_id: rooms_w_confirmations[index].confirmation_no, // reservation confirmation number
                  value: Number.parseFloat(totalRevenue).toFixed(2), // total for stay 
                  tax: Number.parseFloat(taxes).toFixed(2), // Total tax and fee 
                  shipping: 0, // 0 hard coded 
                  currency: property?.currency ||  "USD", // Rate code currency or property currency 
                  coupon: discount_applied?.name ? discount_applied?.name : "", //  if any promocode is applied then promo code 
                  items: []
              }
            // eslint-disable-next-line no-undef
              gtag("event", "purchase", gtagPayload);
              console.log('Event: purchase', gtagPayload)
          }

          // eslint-disable-next-line no-undef
          if (dataLayer) {  
            let payload = {
              event: "confirm",
              ecommerce: {
                purchase: {
                  actionField: {
                    id: rooms_w_confirmations[index].confirmation_no,
                    affiliation: "Website",
                    revenue: Number.parseFloat(totalRevenue).toFixed(2),
                    tax: Number.parseFloat(taxes).toFixed(2),
                    shipping: 0.0,
                    coupon: null,
                  },
                  products: [
                    {
                      id: rooms_w_confirmations[index].confirmation_no,
                      name: room_details.room.name,
                      category: null,
                      variant: null,
                      brand: null,
                      quantity: 1,
                      price: Number.parseFloat(totalRevenue).toFixed(2),
                    },
                  ],
                },
              },
              propertyName: property.name,
              rateCode: room_details.room.code,
              rateName: room_details.rate.name,
              dailyRate: Number.parseFloat(
                room_details.rate_details[0]?.amount
              ).toFixed(2),
              arrivalDate: room_details.check_in_date,
              departureDate: room_details.check_out_date,
              confirmationDate: dayJs().utc().format("YYYY-MM-DD"),
              arrivalMonth: dayJs(room_details.check_in_date)
                .utc()
                .format("MMM"),
              arrivalYear: dayJs(room_details.check_in_date)
                .utc()
                .format("YYYY"),
              arrivalDayOfWeek: dayJs(room_details.check_in_date)
                .utc()
                .format("dddd"),
              guestQuantity:
                room_details.number_of_adults + room_details.number_of_children,
              adultQuantity: room_details.number_of_adults,
              childQuantity: room_details.number_of_children,
              daysToArrival: dayJs(room_details.check_in_date)
                .utc()
                .diff(dayJs().utc(), "days"),
              language: property.language,
              promoCode:
                discount_applied && discount_applied.name
                  ? discount_applied.name
                  : "",
              currency: property.currency,
              roomRevenue: Number.parseFloat(totalRevenue).toFixed(2),
              totalRevenue: Number.parseFloat(totalRevenue).toFixed(2),
              roomQuantity: 1,
              nightsQuantity: dayJs(room_details.check_out_date)
                .utc()
                .diff(dayJs(room_details.check_in_date).utc(), "days"),
              transactionID: rooms_w_confirmations[index].confirmation_no,
              taxes: Number.parseFloat(taxes).toFixed(2),
              roomName: room_details.room.name,
              roomCode: room_details.room.code,
            };
            ///eslint-disable-next-line no-undef
            dataLayer.push(payload);
            //eslint-disable-next-line no-undef
            console.log({dataLayer})
          }
        });
      } catch (error) {
        console.log(error);
      }

      if (state.accessibility_mode) {
        Vue.$notify({
          type: "info",
          message: "Reservation is being created",
          title: "Notify",
        });
      }
    } catch (error) {
      console.log(error);
      commit(
        "create_wizard_store/SET_ERROR",
        {
          status: error.response.status,
          message:
            "Unable to create reservation now. Please try again after some time.",
          create_reservation: false,
        },
        { root: true }
      );
    }
    await commit("CHANGE_STEP", "confirmation");
    setTimeout(() => {
      commit("WIZARD_LOADING", false);
    }, 100);
  },

  resetStateAndGotoResrvation({ state, dispatch, commit }, payload) {
    let queryParams = Clone(router.currentRoute.query);
    commit("RESET_STATE");
    commit("CHANGE_STEP", "search");
    commit("SET_EDIT_STAY_DETAILS", false);
    router.push({ name: payload, query: queryParams }).catch(() => {});
  },

  async getBookingProfile({ state, commit, dispatch }, params) {
    if (store.state.keyFromUrl) params.key = store.state.keyFromUrl;
    if (store.state.tokenFromUrl) params.token = store.state.tokenFromUrl;
    let property = state.property.details;
    let payload = null;
    const url = `${property.region.url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property.id}/booking-engine/new-batch-booking`;
    if (
      state.editStayDetails &&
      state.searchReservationResult?.reservation_details?.id
    ) {
      payload = {
        existing_reservation_id:
          state.searchReservationResult?.reservation_details?.id,
      };
    }
    return AwsWafHandler.post(url, payload, { params })
      .then((res) => {
        commit("SET_BOOKING_PROFILE", res.data);

        let stepsToRemove = [];
        if (
          (res?.data?.restrictions?.booking_profile?.restricted_addons || [])
            .length === 0
        ) {
          stepsToRemove.push("addons");
        }
        if (
          !state.property?.details?.website_config?.enable_loyalty_flow ||
          !(
            this.state.logged_in_user_details &&
            this.state.logged_in_user_details.account_number &&
            this.state.logged_in_user_details.allow_redeem_points
          )
        ) {
          stepsToRemove.push("pointsRedemption");
        }
        if (stepsToRemove.length > 0)
          dispatch("property/setWizardSteps", stepsToRemove);
        else dispatch("property/setWizardSteps");

        if (
          state.editStayDetails &&
          state.searchReservationResult?.reservation_details?.id
        ) {
          let reservation_result = {
            conf_num: state.searchReservationResult.conf_num,
            request_token: state.searchReservationResult.request_token,
            reservation_details: deepClone(res.data?.existing_reservation),
            stay_modification_allowed:
              state.searchReservationResult.stay_modification_allowed,
          };
          store.commit("SET_SEARCH_RESERVATION_RESULT", reservation_result);
        }
      })
      .catch((error) => {
        commit("SET_ERROR", null, { root: true });
      });
  },

  editStayDetails({ state, commit, dispatch }) {
    let route_queries = router.currentRoute.query;

    if (route_queries.skip_search) {
      dispatch("setSearchDataFromQueryParams", route_queries);
    }
    if (state.editStayDetails)
      dispatch(
        "setSearchDataFromExistingReservation",
        state.searchReservationResult?.reservation_details
      );

    commit("SET_SELECT_ROOM_LOADER", true);

    let params = { count: state.search_data.rooms.length };
    if (store.state.keyFromUrl) {
      params.key = store.state.keyFromUrl;
      params.token = store.state.tokenFromUrl;
    }
    let property = state.property.details;
    let payload = null;
    const url = `${property.region.url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property.id}/booking-engine/new-batch-booking`;

    if (state.searchReservationResult?.reservation_details?.id) {
      payload = {
        existing_reservation_id:
          state.searchReservationResult?.reservation_details?.id,
      };
    }
    return AwsWafHandler.post(url, payload, { params });
  },
};
