<template>
    <div v-if="propertyDetails && this.propertyDetails.id" class="search-reservation-wrapper flexed-column">
        <default-header/>
        <div class="search-content flexed justify-center">
             <router-view class="main"/>
        </div>
    </div>
</template>

<script>
import defaultHeader from '@/components/headers/default-header.vue'
import { mapState } from 'vuex';
    export default {
        components: { defaultHeader },
        name: 'SearchReservationWrapper',
        computed: {
           ...mapState({
                propertyDetails: (state) => state.property?.details,
            }),
        },
        beforeMount () {
            if(!this.propertyDetails || !this.propertyDetails.id) {
                this.goto('booking')
                return
            }
        },
        methods: {
            goto(name) {
                 this.$router.push({name: name}).catch(() => {})
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~mixins';
    .search-reservation-wrapper{
        height: 100vh;
        overflow: hidden;
        .search-content {
            margin: 10px;
            margin-top: 30px;
            flex: 1;
            overflow: auto;
            .main{
                width: 900px;
            }
        }
    }
    @include media('<=tablet') {
         .search-reservation-wrapper{
             .search-content {
                 margin-top: 10px;
             }
         }
    }
</style>
